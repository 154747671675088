import { faDashboard, faFilePowerpoint, faGear, faHamburger, faLaptopFile, faUser, faUserTie, } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { logoutService } from "../../services/auth_service"
import { Collapse, Ripple, initTE } from "tw-elements"
import Logo from "../../images/sparta.png"


const SidebarRelawan = () => {
    const navigate = useNavigate()
    const pathName = window.location.pathname

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [open, setOpen] = useState(false)
    const [collapseRelawan, setCollapseRelawan] = useState(false)
    const [collapseVoter, setCollapseVoter] = useState(false)

    const handleMenu = () => {
        if (open === true) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }

    const handleLogout = () => {
        localStorage.clear()
        navigate('/')
        if (accessToken != null) {
            logoutService(accessToken);
        }
    }

    const handleCollapseRelawan = () => {
        if (collapseRelawan === true) {
            setCollapseRelawan(false)
        } else {
            setCollapseRelawan(true)
        }
    }

    const handleCollapseVoter = () => {
        if (collapseVoter === true) {
            setCollapseVoter(false)
        } else {
            setCollapseVoter(true)
        }
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)


        initTE({ Collapse, Ripple, })
    }, [])
    return (
        <>
            <div className="h-screen w-64 bg-yellow-400 hidden md:block overflow-auto scrollbar-none">
                <div className="w-full bg-yellow-400 sticky top-0 z-20 space-y-3 pt-3">
                    <div className="w-[50%] aspect-square mx-auto bg-white rounded-full">
                        <img src={Logo} alt="Logo-Sparta" className="w-full mx-auto" />
                    </div>

                    <div className="pl-3">
                        <div className="w-full h-1 rounded-full  bg-white"></div>
                    </div>
                </div>

                {/* Desktop Version */}
                <div className="flex flex-col pl-3 pt-3 font-semibold text-sm space-y-2">
                    <a href="/dashboard">
                        <div class="group flex flex-col items-end">
                            <div class="text-black px-3 py-2 h-10 w-full rounded-l-full font-oswald   delay-200 transition-all text-start group-hover:hover:text-black relative space-x-2 flex items-center">
                                <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                                <span>Dashboard</span>
                            </div>
                            <div class={`bg-white h-10 -mt-10 group-hover:w-full transition-all duration-500 rounded-l-full  ${pathName === '/dashboard' ? 'w-full' : 'w-0'}`}>
                            </div>
                        </div>
                    </a>

                    <a href="/dashboard/voter">
                        <div class="group flex flex-col items-end">
                            <div class="text-black px-3 py-2 h-10 w-full rounded-l-full font-oswald   delay-200 transition-all text-start group-hover:hover:text-black relative space-x-2 flex items-center">
                                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                <span>Data Voter</span>
                            </div>
                            <div class={`bg-white w-0 h-10 -mt-10 group-hover:w-full transition-all duration-500 rounded-l-full ${pathName.startsWith('/dashboard/voter') ? 'w-full' : 'w-0'} `}>
                            </div>
                        </div>
                    </a>

                    <a href="/dashboard/presensi/voter">
                        <div class="group flex flex-col items-end">
                            <div class="text-black px-3 py-2 h-10 w-full rounded-l-full font-oswald   delay-200 transition-all text-start group-hover:hover:text-black relative space-x-2 flex items-center">
                                <FontAwesomeIcon icon={faFilePowerpoint}></FontAwesomeIcon>
                                <span>Absesi Voter</span>
                            </div>
                            <div class={`bg-white w-0 h-10 -mt-10 group-hover:w-full transition-all duration-500 rounded-l-full ${pathName.startsWith('/dashboard/presensi/voter') ? 'w-full' : 'w-0'} `}>
                            </div>
                        </div>
                    </a>

                    {/* Form C1 */}
                    <a href="/dashboard/form-c1">
                        <div class="group flex flex-col items-end">
                            <div class="text-black px-3 py-2 h-10 w-full rounded-l-full font-oswald   delay-200 transition-all text-start group-hover:hover:text-black relative space-x-2 flex items-center">
                                <FontAwesomeIcon icon={faLaptopFile}></FontAwesomeIcon>
                                <span>Form C1</span>
                            </div>
                            <div class={`bg-white w-0 h-10 -mt-10 group-hover:w-full transition-all duration-500 rounded-l-full ${pathName.startsWith('/dashboard/form-c1') ? 'w-full' : 'w-0'} `}>
                            </div>
                        </div>
                    </a>

                    {/* Setting */}
                    <a href="/dashboard/pengaturan">
                        <div class="group flex flex-col items-end">
                            <div class="text-black px-3 py-2 h-10 w-full rounded-l-full font-oswald   delay-200 transition-all text-start group-hover:hover:text-black relative space-x-2 flex items-center">
                                <FontAwesomeIcon icon={faGear}></FontAwesomeIcon>
                                <span>pengaturan</span>
                            </div>
                            <div class={`bg-white w-0 h-10 -mt-10 group-hover:w-full transition-all duration-500 rounded-l-full ${pathName.startsWith('/dashboard/pengaturan') ? 'w-full' : 'w-0'} `}>
                            </div>
                        </div>
                    </a>

                    <button type="button" onClick={handleLogout} className="w-full py-2 px-3 flex space-x-3 items-center font-semibold rounded-md transition-colors duration-300 hover:bg-red-500 ${pathName bg-red-400">
                        <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                        <p>Keluar</p>
                    </button>
                </div>
            </div>

            {/* Mobile Version */}
            <button type="button" onClick={handleMenu} className="md:hidden absolute z-[1250] bg-yellow-400 h-14 aspect-square bottom-5 right-3 rounded-full text-black">
                <FontAwesomeIcon icon={faHamburger} className="text-2xl"></FontAwesomeIcon>
            </button>            <div className={`absolute z-[1240] bottom-10 right-8 bg-black/50 rounded-xl text-sm backdrop-blur-sm md:hidden flex flex-col justify-between py-5 transition-all duration-300 overflow-hidden ${open === true ? 'w-40 h-72' : 'w-0 h-0'} `}>
                <div>
                    <a href="/dashboard">
                        <div className={`w-full py-2 px-3 flex space-x-3 items-center justify-center font-semibold transition-colors duration-300 text-white hover:text-yellow-400 hover:bg-black/30 ${pathName === "/dashboard" ? 'bg-black/30 text-yellow-400' : 'text-white bg-none'}`}>
                            <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                            <p>Dashboard</p>
                        </div>
                    </a>
                    <a href="/dashboard/relawan">
                        <div className={`w-full py-2 px-3 flex space-x-3 items-center justify-center font-semibold transition-colors duration-300 text-white hover:text-yellow-400 hover:bg-black/30 ${pathName.startsWith('/dashboard/relawan') ? 'bg-black/30 text-yellow-400' : 'text-white bg-none'}`}>
                            <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                            <p>Relawan</p>
                        </div>
                    </a>
                    <a href="/dashboard/voter">
                        <div className={`w-full py-2 px-3 flex space-x-3 items-center justify-center font-semibold transition-colors duration-300 text-white hover:text-yellow-400 hover:bg-black/30 ${pathName.startsWith('/dashboard/responden') ? 'bg-black/30 text-yellow-400' : 'text-white bg-none'}`}>
                            <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                            <p>voter</p>
                        </div>
                    </a>
                </div>
                <button type="button" onClick={handleLogout} className="w-full py-2 px-3 flex space-x-3 items-center font-semibold self-end place-self-end justify-self-end rounded-md transition-colors duration-300 hover:bg-red-500 ${pathName bg-red-400">
                    <FontAwesomeIcon icon={faDashboard}></FontAwesomeIcon>
                    <p>Keluar</p>
                </button>
            </div>

            {/* End Mobile Version */}
        </>
    )
}

export default SidebarRelawan