import apiService from "./api_service";


export const loginService = async (email, password) => {
    try {
        
        const body = {
            'email' : email,
            'password' : password
        }

        const response = await apiService.post(`login`, body, {
            headers: {   
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const logoutService = async (accessToken) => {
    try {
        
        const response = await apiService.post(`logout`,{}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getProfile = async (accessToken) => {
    try {
        
        const response = await apiService.get(`profile`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};