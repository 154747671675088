import apiService from "./api_service";

export const getDistrict = async (regencyId) => {
    try {
        const response = await apiService.get(`district/${regencyId}`);
        return response
    } catch (error) {
        console.error('Error fetching regency data:', error);
    }
};


export const getVillage = async (districtId) => {
    try {
        const response = await apiService.get(`village/${districtId}`); 
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }

};

export const getTps = async (villageId) => {
    try {
        const response = await apiService.get(`tps/${villageId}`);
            return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }

};

