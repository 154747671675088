import React from "react"

const TemplateText = ({name, value}) => {
    return (
        <div className="flex items-center space-x-3 text-xs md:text-sm">
            <div className="w-28 md:w-56 font-semibold text-end">{name}</div>
            <div>:</div>
            <input type="text" value={value} disabled className="px-3 py-2 text-sm outline-none border-2 w-full rounded-md " />
            {/* <div>{value}</div> */}
        </div>
    )
}

export default TemplateText