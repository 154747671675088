import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { faCircleChevronLeft, faFileExport, faPlus, faSearch, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getCaleg, postCaleg } from "../../../services/caleg_service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
    Select,
    Dropdown,
    Modal,
    Input,
    Ripple,
    initTE,
} from "tw-elements";
import { getDapil } from "../../../services/dapil_service";
import { getCalegType, showCalegType } from "../../../services/caleg_type_service";

const CalegList = () => {

    const { type_id } = useParams()
    const navigate = useNavigate()

    const [caleg, setCaleg] = useState([])
    const [keyword, setKeyword] = useState('')
    const [totalCaleg, setTotalCaleg] = useState(0)

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [isLoadingPost, setIsLoadingPost] = useState(false)


    const [nik, setNik] = useState('')
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const [openModalImport, setOpenModalImport] = useState(false)
    const [openModalManualAdd, setOpenModalManualAdd] = useState(false)
    const [openModalRekapImport, setOpenModalRekapImport] = useState(false)

    const [createCount, setCreateCount] = useState(0)
    const [updateCount, setUpdateCount] = useState(0)
    const [failedCount, setFailedCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [message, setMessage] = useState('')
    const [showPopUpManualAdd, setShowPopUpManualAdd] = useState(false)

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [calegType, setCalegType] = useState([])

    const [regencyId, setRegencyId] = useState(3205)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [namaPartai, setNamaPartai] = useState('')
    const [noUrut, setNoUrut] = useState('')
    const [dapilId, setDapilId] = useState('')
    const [dapil, setDapil] = useState([])


    const handleChangeName = (e) => {
        const newName = e.target.value;
        setName(newName)
    };

    const handleChangeEmail = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail)
    };

    const handleChangeNamaPartai = (e) => {
        const newNamaPartai = e.target.value;
        setNamaPartai(newNamaPartai)
    };

    const handleChangeNoUrut = (e) => {
        const newNoUrut = e.target.value;
        setNoUrut(newNoUrut)
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoadingPost(true)
        postCaleg(accessToken, roleName, type_id, calegType?.name, name, email, namaPartai, noUrut, dapilId ?? '').then((response) => {
            console.log(response);
            if (response && response.status == 200) {
                setIsLoadingPost(false)
                setOpenModalManualAdd(false)
                setShowPopUpManualAdd(true)
                setMessage(response.data.message)
                getCaleg(accessToken, roleName, type_id).then((response) => {
                    if (response && response.status === 200) {
                        setCaleg(response.data.data)
                    } else {
                        setMessage(response.data.message)
                        setIsLoadingPost(false)
                        setShowPopUpManualAdd(true)
                        setCaleg([])
                    }
                })
            }
        });
    }

    const handleRegency = async (e) => {
        const regencyId = e.target.value;
        setRegencyId(regencyId)
        getDapil(accessToken, roleName, regencyId).then((response) => {
            if (response && response.status === 200) {
                setDapil(response.data.data)
                setDapilId(response.data.data.id)
            } else {
                setDapil([])
            }
        })

    }

    const handleDapil = async (e) => {
        const dapilId = e.target.value;
        setDapilId(dapilId)
    }

    const handleAdminDetail = (calegId) => {
        navigate(`/dashboard/caleg/detail/${calegId}`)
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        getCaleg(accessToken, roleName, type_id).then((response) => {
            setIsLoading(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setCaleg(response.data.data)
                setTotalCaleg(response.data.meta.total);

            } else {
                setCaleg([])
            }
        })

        showCalegType(accessToken, roleName, type_id).then((response) => {
            if (response && response.status === 200) {
                setCalegType(response.data.data)
            } else {
                setCalegType(null)
            }
        });

        getDapil(accessToken, roleName, regencyId).then((response) => {
            if (response && response.status === 200) {
                setDapil(response.data.data)
                setDapilId(response.data.data.id)
            } else {
                setDapil([])
            }
        })

        initTE({ Select, Modal, Input, Dropdown, Ripple })
    }, [type_id.navigate])
    return (
        <>
            <Helmet>
                <title>Daftar Caleg - Sparta 24</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 w-full h-14 bg-yellow-400 z-30">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-5 text-white">
                        <div className="flex items-center space-x-3">
                            <button type="button" onClick={() => navigate(`/dashboard/caleg`)}>
                                <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                                <span className="ml-3 font-semibold">Kembali</span>
                            </button>
                            {/* Search */}
                            <form>
                                <div className="flex items-center text-sm pl-3">
                                    <input
                                        type="search"
                                        value={keyword}
                                        // onChange={handleSearchChange}
                                        className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                        placeholder="telusuri"
                                    ></input>
                                    <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>
                            {/* End Search */}

                            <div className="flex items-center space-x-3 font-semibold mb-5 md:mb-0">
                                {refresh === true ?
                                    <div className="flex items-center justify-center w-10 h-10">
                                        <div
                                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div> :
                                    <p className="font-semibold text-2xl">{totalCaleg.toLocaleString('id-ID')}</p>
                                }
                                <p>Caleg</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button
                                type="button"
                                onClick={() => setOpenModalManualAdd(true)}
                                class="font-semibold text-sm space-x-3 bg-yellow-400 px-5 py-2 rounded-md text-black hover:bg-yellow-500 transition-colors">
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                <span>Tambat Data</span>
                            </button>

                        </div>
                    </div>
                </div>

                {/* Grid View Calef */}
                <div className="w-full grid grid-cols-3 gap-5 p-5">
                    {caleg.map((dataCaleg) => (
                        <a href={`/dashboard/caleg/${type_id}/detail/${dataCaleg?.id}`} className="cursor-pointer w-full aspect-[12/16] bg-yellow-400 rounded-ld rounded-xl relative overflow-hidden hover:scale-105 transition-all duration-300">
                            <div className="h-16 aspect-square z-30 absolute top-3 right-3 flex flex-col items-center justify-center text-sm rounded-md font-semibold bg-yellow-400 ">
                                <p>No Urut</p>
                                <p className="text-xl">{dataCaleg.no_urut}</p>
                            </div>
                            {dataCaleg?.image == null ?
                                <div className="w-full aspect-[12/16] bg-yellow-100 rounded-md drop-shadow-md flex items-center justify-center">
                                    <FontAwesomeIcon icon={faUserTie} className="w-[80%] h-[80%] object-contain"></FontAwesomeIcon>
                                </div> :
                                <img src={`http://web.sparta-24.com/${dataCaleg?.image}`} alt="Caleg - SPARTA 24" className="w-full aspect-[12/16] rounded-md object-cover object-center drop-shadow-lg" />
                            }
                            <div className="w-full h-[15%] flex items-center justify-center font-semibold text-xl absolute bottom-0 z-10 bg-yellow-100/50 backdrop-blur-sm">
                                <p>{dataCaleg.name.toUpperCase()}</p>
                            </div>
                        </a>
                    ))}
                </div>

            </LayoutSuperAdmin>

            {/* Modal Manual Relawan */}
            <div className={`absolute top-0 h-screen w-full flex items-center justify-center ${openModalManualAdd === false ? 'z-0' : 'z-[1055]'}`}>
                <form onSubmit={handleSubmit} className={`${openModalManualAdd === false ? 'w-0 h-0 rounded-full' : 'w-full h-full'} bg-black/50 flex items-center justify-center transition-all duration-700 overflow-hidden`}>
                    <div className="h-fit w-[90%] md:w-[70%] lg:w-[500px] bg-white rounded-xl">,
                        {/* Header */}
                        <div className="py-5 drop-shadow-md text-center border-b-2">
                            <p className="font-semibold text-xl">Tambah Caleg</p>
                        </div>
                        {/* Body */}
                        <div className="p-5 space-y-3">
                            <div class="relative w-full" data-te-input-wrapper-init>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={handleChangeName}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="Nama lengkap" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                >
                                    Nama Lengkap
                                </label>
                            </div>

                            <div class="relative w-full" data-te-input-wrapper-init>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="example@gmail.com" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                >
                                    Email
                                </label>
                            </div>

                            <div class="relative w-full" data-te-input-wrapper-init>
                                <input
                                    type="text"
                                    value={namaPartai}
                                    onChange={handleChangeNamaPartai}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="No Urut" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                >
                                    Nama Partai
                                </label>
                            </div>

                            <div class="relative w-full" data-te-input-wrapper-init>
                                <input
                                    type="number"
                                    value={noUrut}
                                    onChange={handleChangeNoUrut}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="No Urut" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                >
                                    No Urut
                                </label>
                            </div>
                            {calegType?.name === "DPRD KABUPATEN" ?
                                <div className="w-full space-y-3">
                                    <div className="w-full">
                                        <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                                            <option value="3205">KABUPATEN GARUT</option>
                                            <option value="3206">KABUPATEN TASIKMALAYA</option>
                                            <option value="3278">KOTA TASIKMALAYA</option>
                                        </select>
                                    </div>

                                    <div className="w-full">
                                        <select value={dapilId} data-te-select-init data-te-select-filter="true" onChange={handleDapil}>
                                            {dapil?.map((item) => (
                                                <option value={item.id} >Dapil {item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                : ''}
                        </div>
                        {/* Footer */}
                        <div className="py-3 drop-shadow-md text-center border-t-2 flex items-center px-5 w-full justify-end space-x-3">
                            <button type="button" onClick={() => setOpenModalManualAdd(false)} className="flex items-center justify-center w-32 py-2 bg-red-400 hover:bg-red-500 transition-all duration-300 rounded-md font-semibold">
                                Kembali
                            </button>

                            <button type="submit" className="flex items-center justify-center w-32 py-2 bg-yellow-400 hover:bg-yellow-500 transition-all duration-300 rounded-md font-semibold">
                                {isLoadingPost === true ? <svg className="animate-spin h-5 w-5 mr-3 bg-black" viewBox="0 0 24 24">

                                </svg> : ''}
                                Simpan
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CalegList