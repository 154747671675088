import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"

const CalegAdmin = () => {
    return (
        <>
            <Helmet>
                <title>Admin Caleg - SPARTA 24</title>
            </Helmet>
            <LayoutSuperAdmin>

            </LayoutSuperAdmin>
        </>
    )
}

export default CalegAdmin