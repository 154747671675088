import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useHref, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getRelawanVoter, showRelawan } from "../../../services/relawan_service"
import TemplateText from "../../../components/template_text"
import {
    Collapse,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCircleChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons"

const RelawanDetail = () => {
    const navigate = useNavigate()
    const history = useHref()
    const { id } = useParams()

    let [number, setNumber] = useState(1)

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [relawan, setRelawan] = useState(null)


    const [voter, setVoter] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalVoter, setTotalVoter] = useState(0)

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [connection, setConnection] = useState(true)

    const handleRedirectToDetail = (voterId) => {
        navigate(`/dashboard/voter/detail/${voterId}`)
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newCurrentPage = currentPage - 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            // getVoter(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
            //     setIsLoading(true)
            //     setConnection(true)
            //     if (response && response.status === 200) {
            //         setIsLoading(false)
            //         setVoter(response.data.data);
            //         setTotalVoter(response.data.meta.total);
            //         setNumber(response.data.meta.from)

            //         setCurrentPage(response.data.meta.current_page)
            //         setLastPage(response.data.meta.last_page)
            //     } else {
            //         setIsLoading(false)
            //         setVoter([])
            //     }
            // });
        }
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            const newCurrentPage = currentPage + 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            // getVoter(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
            //     setIsLoading(true)
            //     setConnection(true)
            //     if (response && response.status === 200) {
            //         setIsLoading(false)
            //         setVoter(response.data.data);
            //         setTotalVoter(response.data.meta.total);
            //         setNumber(response.data.meta.from)

            //         setCurrentPage(response.data.meta.current_page)
            //         setLastPage(response.data.meta.last_page)
            //     } else {
            //         setIsLoading(false)
            //         setVoter([])
            //     }
            // });
        }
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken == null) {
            navigate('/login')
        } else {
            showRelawan(accessToken, roleName, id).then((response) => {
                if (response && response.status === 200) {
                    setRelawan(response.data.data)
                } else {
                    // confirm('dsdsd')
                }
            });

            getRelawanVoter(accessToken, roleName, id).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setVoter(response.data.data);
                    setTotalVoter(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setVoter([])
                }
            })
        }

        initTE({ Collapse })

    }, [id, navigate]);
    return (
        <>
            <Helmet>
                <title>Detail Relawan</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full z-30">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-5 md:px-10 text-white">
                        <a href="/dashboard/relawan" className="flex items-center space-x-3">
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                            <p className="hidden md:block font-semibold">Kembali</p>
                        </a>
                        <p class="text-xl font-semibold text-white text-center">Detail Relawan</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">

                        </button>
                    </div>
                </div>

                {/* Voter By Relawan */}

                <div id="accordionExample">
                    <div
                        class="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                        <h2 class="mb-0" id="headingOne">
                            <button
                                class="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] font-semibold"
                                type="button"
                                data-te-collapse-init
                                data-te-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne">
                                Data Relawan
                                <span
                                    class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            class="!visible"
                            data-te-collapse-item
                            data-te-collapse-show
                            aria-labelledby="headingOne"
                            data-te-parent="#accordionExample">
                            <div class="md:px-5 md:py-4">
                                <div className="w-full flex flex-col md:flex-row items-start p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3">
                                    <div className="w-full md:w-1/2 space-y-3">
                                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                                            <p className="font-semibold">Detail Relawan</p>
                                        </div>
                                        <TemplateText name="No Kartu Keluarga" value={relawan?.nkk}></TemplateText>
                                        <TemplateText name="NIK" value={relawan?.nik}></TemplateText>
                                        <TemplateText name="Nama Lengkap" value={relawan?.nama}></TemplateText>
                                        <TemplateText name="Tempat Lahir" value={relawan?.tempat_lahir}></TemplateText>
                                        <TemplateText name="Tanggal Lahir" value={relawan?.tanggal_lahir}></TemplateText>
                                        <TemplateText name="Usia" value={relawan?.usia}></TemplateText>
                                        {/* <TemplateText name="Status Perkawinan" value={relawan?.status_perkawinan}></TemplateText> */}
                                        <TemplateText name="No TPS" value={relawan?.no_tps}></TemplateText>
                                        <TemplateText name="Role" value={relawan?.role_name}></TemplateText>
                                    </div>
                                    <div className="w-full md:w-1/2 space-y-3">
                                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                                            <p className="font-semibold">Alamat Relawan</p>
                                        </div>
                                        <TemplateText name="Provinsi" value={relawan?.alamat?.provinsi}></TemplateText>
                                        <TemplateText name="Kabupaten" value={relawan?.alamat?.kabupaten}></TemplateText>
                                        <TemplateText name="Kecamatan" value={relawan?.alamat?.kecamatan}></TemplateText>
                                        <TemplateText name="Kecamatan" value={relawan?.alamat?.kelurahan}></TemplateText>
                                        <TemplateText name="rt" value={relawan?.alamat?.rt}></TemplateText>
                                        <TemplateText name="rw" value={relawan?.alamat?.rw}></TemplateText>
                                        <TemplateText name="detail" value={relawan?.alamat?.detail}></TemplateText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                        <h2 class="accordion-header mb-0" id="headingThree">
                            <button
                                class="group relative flex w-full items-center justify-between border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] [&[data-te-collapse-collapsed]]:rounded-b-[15px] [&[data-te-collapse-collapsed]]:transition-none font-semibold"
                                type="button"
                                data-te-collapse-init
                                data-te-collapse-collapsed
                                data-te-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree">
                                <span>Daftar Voter By Relawan</span>
                                <p className="ml-5">({totalVoter})</p>
                                <span
                                    class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            class="!visible hidden"
                            data-te-collapse-item
                            aria-labelledby="headingThree"
                            data-te-parent="#accordionExample">
                            <div class="px-5 py-4">
                                <div className="w-full px-3 md:px-5 overflow-x-auto  scrollbar-thin scrollbar-track-black scrollbar-thumb-blue-700 scrollbar-track-rounded-full scrollbar-thumb-rounded-full relative ">
                                    {/* Desktop Tabel */}
                                    <table className="min-w-max w-full hidden md:block">
                                        <thead className="w-full">
                                            <tr className="text-sm w-full">
                                                <th className="w-16 py-2 aspect-square border-2 ">No</th>
                                                <th className="py-2 aspect-square border-2 px-2 text-start ">No Kartu Keluarga</th>
                                                <th className="py-2 aspect-square border-2 px-2 text-start ">Nik</th>
                                                <th className="w-72 py-2 aspect-square border-2 px-2 text-start overflow-auto">Nama</th>
                                                <th className="py-2 aspect-square border-2 px-2 text-start ">Kota / Kabupaten</th>
                                                <th className="py-2 aspect-square border-2 px-2 text-start ">Kecamatan</th>
                                                <th className="py-2 aspect-square border-2 px-2 text-start ">Desa / Kelurahan</th>
                                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">TPS</th>
                                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading === false ?
                                                voter.map((dataVoter) => (
                                                    <tr className={`text-sm ${number % 2 === 1 ? 'bg-white hover:bg-gray-400' : 'bg-gray-200 hover:bg-gray-400'}`}>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer text-center">{number++}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer bg-gray px-3 w-fit">{dataVoter.nkk}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-2 py-2 w-fit">{dataVoter.nik}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-2 py-2 w-fit">{dataVoter.nama}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-2 py-2 w-fit">{dataVoter.alamat.kabupaten}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-2 py-2 w-fit">{dataVoter.alamat.kecamatan}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-2 py-2 w-fit">{dataVoter.alamat.kelurahan}</td>
                                                        <td onClick={() => handleRedirectToDetail(dataVoter.id)} className="cursor-pointer px-3 py-2 text-center">TPS {dataVoter.no_tps}</td>
                                                        <td className="text-center px-3 py-2">
                                                            <button
                                                                type="button"
                                                                // onClick={() => handleDeleteVoter(dataVoter.id)}
                                                                data-te-ripple-init
                                                                data-te-ripple-color="light"
                                                                data-te-toggle="popconfirm"
                                                                data-te-popconfirm-mode="inline"
                                                                data-te-message="Apakah Anda yakin akan menghapus data ini..?"
                                                                data-te-cancel-text="Tidak"
                                                                data-te-ok-text="Ya"
                                                                className="confirm-delete text-red-500">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : Array.from({ length: 20 }, (_, j) => (
                                                    <tr key={j} className="animate-pulse w-full">
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <td key={i} className="px-3 py-2">
                                                                <div className="h-5 bg-slate-200 rounded"></div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    {/* End Desktop Tabel */}

                                    {/* Mobile Version Card */}
                                    <div className="space-y-3 pt-3 md:hidden">
                                        {isLoading === false ?
                                            voter.map((dataVoter) => (
                                                <div className="w-full h-16 bg-yellow-50 drop-shadow-md flex items-center justify-between text-sm rounded-md px-3">
                                                    <div className="flex space-x-3">
                                                        {dataVoter.image == null ?

                                                            <div className="h-10 aspect-square bg-yellow-400 rounded-full"></div> :
                                                            <img src={dataVoter.image} alt="Voter Sparta" className="w-10 aspect-square rounded-md object-cover object-center"></img>}
                                                        <div>
                                                            <p className="font-semibold ">{dataVoter.nama}</p>
                                                            <p>{dataVoter.nik}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col items-center justify-center">
                                                        <p>TPS</p>
                                                        <p>{dataVoter.no_tps}</p>
                                                    </div>
                                                </div>
                                            )) : ''}
                                    </div>
                                    {/* End Card Mobile Version */}

                                    <div className="w-full bg-white h-3"></div>
                                    <div className="w-full flex items-center justify-end space-x-3 py-5 bg-gray-200 px-5">
                                        <button type="button"
                                            onClick={handlePreviousPage}
                                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                                            <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                                            <span className="ml-3">Kembali</span>
                                        </button>
                                        <p className="text-xl font-bold ">{currentPage}</p>
                                        <button type="button"
                                            onClick={handleNextPage}
                                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                                            <span className="mr-3">Selanjutnya</span>
                                            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default RelawanDetail