import apiService from "./api_service";

export const getDapil = async (accessToken, roleName, regencyId) => {
    try {

        const response = await apiService.get(`${roleName}/dapil?regency_id=${regencyId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};