import { useEffect, useState } from "react";
import SidebarSuperAdmin from "../sidebars/sidebar_super_admin"
import { useNavigate } from "react-router-dom";
import SidebarAdmin from "../sidebars/sidebar_admin";
import SidebarRelawan from "../sidebars/sidebar_relawan";
import SidebarCaleg from "../sidebars/sidebar_caleg";

const LayoutSuperAdmin = ({ children }) => {
    const navigate = useNavigate()
    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    let sidebar;
    switch (roleName) {
        case 'super-admin':
            sidebar = <SidebarSuperAdmin></SidebarSuperAdmin>
            break;
        case 'admin-sparta':
            sidebar = <SidebarAdmin></SidebarAdmin>
            break;
        case 'admin-caleg':
            sidebar = <SidebarAdmin></SidebarAdmin>
            break;
        case 'relawan-sparta':
            sidebar = <SidebarRelawan></SidebarRelawan>
            break;
        case 'relawan-caleg':
            sidebar = <SidebarRelawan></SidebarRelawan>
            break;
        case 'caleg':
            sidebar = <SidebarCaleg></SidebarCaleg>
            break;
            
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken === null) {
            navigate('/login')
        }
    }, [navigate]);
    return (
        <div className="fixed h-screen w-full flex z-50">
            {sidebar}
            <main class="w-full h-screen overflow-auto scrollbar-thin scrollbar-track-black scrollbar-thumb-blue-700 scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                {children}
            </main>
        </div>
    )
}

export default LayoutSuperAdmin