import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useEffect, useState } from "react";
import {
    Select,
    Modal,
    Dropdown,
    Ripple,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faFileExport,
    faFilter,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { deleteDpt, getDpt, searchDpt } from "../../../services/dpt_service";
import { getDistrict, getTps, getVillage } from "../../../services/location_service";

const Dpt = () => {
    const navigate = useNavigate()

    let [number, setNumber] = useState(1)

    const [district, setDistrict] = useState([]);
    const [village, setVillage] = useState([]);
    const [tps, setTps] = useState([]);

    const [regencyId, setRegencyId] = useState(3205);
    const [districtId, setDistrictId] = useState(0);
    const [villageId, setVillageId] = useState(0);
    const [tpsId, setTpsId] = useState(0);

    const [dpt, setDpt] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalDpt, setTotalDpt] = useState(0)

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [connection, setConnection] = useState(true)

    const handleSearchChange = (e) => {
        const newKeyword = e.target.value;
        setKeyword(newKeyword)
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsLoading(true)
        searchDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1, keyword).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setDpt(response.data.data);
                setTotalDpt(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setDpt([])
            }
        });
    }

    const handleDeleteDpt = (dptId) => {
        deleteDpt(accessToken, dptId).then((response) => {
        });
    }

    const handleRegency = async (e) => {
        const regencyId = e.target.value;
        setRegencyId(regencyId)
        setDistrictId(0)
        getDistrict(regencyId).then((response) => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            } else {
                setDistrict([])
            }
        })
        setVillage([])
        setVillageId(0)
        setTps([])
        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)
        setCurrentPage(1)
        getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setDpt(response.data.data);
                setTotalDpt(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setDpt([])
            }
        });
    }

    const handleDistrict = async (e) => {
        const districtId = e.target.value;
        setDistrictId(districtId)
        if (districtId === 0) {
            setTps([])
        } else {
            getVillage(districtId).then((response) => {
                if (response.status === 200) {
                    setVillage(response.data.data)
                } else {
                    setVillage([])
                }
            })
        }

        setVillageId(0)
        setTps([])
        setTpsId(0)

        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)
        getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setDpt(response.data.data);
                setTotalDpt(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setDpt([])
            }
        });

    }

    const handleVillage = async (e) => {
        const villageId = e.target.value;
        setVillageId(villageId)
        console.log(villageId);

        if (villageId === 0) {
            setTps([])
        } else {
            getTps(villageId).then((response) => {
                setTpsId(0)
                console.log(response);
                if (response && response.status === 200) {
                    setTps(response.data)
                } else {
                    setTps([])
                }
            });
        }

        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)

        getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setDpt(response.data.data);
                setTotalDpt(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setDpt([])
            }
        });
    }

    const handleTps = async (e) => {
        const tpsId = e.target.value;
        setTpsId(tpsId)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)
        console.log(tpsId);

        setCurrentPage(1)
        getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setDpt(response.data.data);
                setTotalDpt(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setDpt([])
            }
        });
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newCurrentPage = currentPage - 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setIsLoading(false)
                    setDpt(response.data.data);
                    setTotalDpt(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setDpt([])
                }
            });
        }
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            const newCurrentPage = currentPage + 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response.status === 200) {
                    setIsLoading(false)
                    setDpt(response.data.data);
                    setTotalDpt(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setDpt([])
                }
            });
        }
    }

    useEffect(() => {

        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null") {
            navigate('/login')
        } else {
            getDpt(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setDpt(response.data.data);
                    setTotalDpt(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setDpt([])
                }
            });

            getDistrict(regencyId).then((response) => {

                if (response && response.status === 200) {
                    setDistrict(response.data.data)
                } else {
                    setDistrict([])
                }

            })
        }
        initTE({ Select, Dropdown, Modal, Ripple })
    }, [currentPage, regencyId, districtId, villageId, tpsId, navigate]);
    return (
        <>
            <Helmet>
                <title>Dashboard - Sparta 24</title>
            </Helmet>
            <LayoutSuperAdmin>
                {/* Desktop Header */}
                <div class="sticky top-0 w-full h-14 bg-yellow-400 z-30 hidden md:block">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-5 text-white">
                        <div className="flex items-center space-x-3">
                            <form onSubmit={handleSearch}>
                                <div className="flex items-center text-sm pl-3">
                                    <input
                                        type="search"
                                        value={keyword}
                                        onChange={handleSearchChange}
                                        className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                        placeholder="telusuri"
                                    ></input>
                                    <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>

                            <div className="flex items-center space-x-3 font-semibold mb-5 md:mb-0">
                                {refresh === true ?
                                    <div className="flex items-center justify-center w-10 h-10">
                                        <div
                                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div> :
                                    <p className="font-semibold text-2xl">{totalDpt.toLocaleString('id-ID')}</p>
                                }
                                <p>Dpt</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button id="" type="button" class="font-semibold text-sm space-x-3 bg-green-400 px-5 py-2 rounded-md text-black hover:bg-green-500 transition-colors">
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                                <span>Export Data</span>
                            </button>
                            <div class="relative" data-te-dropdown-ref>
                                <button
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded="false"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    class="font-semibold text-sm space-x-3 bg-yellow-400 px-5 py-2 rounded-md text-black hover:bg-yellow-500 transition-colors">
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                    <span>Tambat Data</span>
                                </button>
                                <ul
                                    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                    aria-labelledby="dropdownMenuButton1"
                                    data-te-dropdown-menu-ref>
                                    <li>
                                        <a
                                            href="/"
                                            class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Action
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Another action
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Something else here
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Emd Desktop Header */}

                {/* Mobile Header */}
                <div className="w-full sticky top-0 bg-blue-500 py-3 px-3 space-y-3 md:hidden z-40">
                    <div className="flex items-center space-x-3">
                        <form onSubmit={handleSearch}>
                            <div className="flex items-center text-sm ">
                                <input
                                    type="search"
                                    value={keyword}
                                    onChange={handleSearchChange}
                                    className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                    placeholder="telusuri"
                                ></input>
                                <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </button>
                            </div>
                        </form>
                        <button
                            type="button"
                            data-te-toggle="modal"
                            data-te-target="#mobileFilter"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                            <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                        </button>

                        <div class="relative" data-te-dropdown-ref>
                            <a
                                type="button"
                                id="dropdownMenuButton2"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </a>
                            <ul
                                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton2"
                                data-te-dropdown-menu-ref>
                                <li>
                                    <a
                                        class="block w-full whitespace-nowrap bg-transparent py-2 px-10 text-sm font-semibold text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        href="#"
                                        data-te-dropdown-item-ref
                                    >
                                        Import
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="block w-full whitespace-nowrap bg-transparent px-10 py-2 text-sm font-semibold text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        href="#"
                                        data-te-dropdown-item-ref
                                    >
                                        Manual
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-center space-x-3 font-semibold md:mb-0 text-white">
                        {refresh === true ?
                            <div className="flex items-center justify-center w-10 h-10">
                                <div
                                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status">
                                    <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                        Loading...
                                    </span>
                                </div>
                            </div> :
                            <p className="font-semibold text-2xl">{totalDpt.toLocaleString('id-ID')}</p>
                        }
                        <p>Dpt</p>
                    </div>
                </div>
                {/* End Mobile Header */}

                {/* Mobile Filter */}
                <div
                    data-te-modal-init
                    class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                    id="mobileFilter"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div
                        data-te-modal-dialog-ref
                        class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] px-3 md:px-0">
                        <div
                            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                            <div
                                class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                {/* <!--Modal title--> */}
                                <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="exampleModalLabel">
                                    Filter Lokasi / Daerah
                                </h5>
                                {/* <!--Close button--> */}
                                <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            {/* <!--Modal body--> */}
                            <div class="relative flex-auto p-3 space-y-3 w-full" data-te-modal-body-ref>
                                <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                                    <option value="3205">KABUPATEN GARUT</option>
                                    <option value="3206">KABUPATEN TASIKMALAYA</option>
                                    <option value="3278">KOTA TASIKMALAYA</option>
                                </select>
                                <select value={districtId} data-te-select-init data-te-select-filter="true" onChange={handleDistrict}>
                                    <option value="" className="text-sm">Kecamatan</option>
                                    {district?.map((item) => (
                                        <option value={item.id} >{item.name}</option>
                                    ))}
                                </select>

                                <select value={villageId} data-te-select-init data-te-select-filter="true" onChange={handleVillage}>
                                    <option value="" className="text-sm">Kelurahan</option>
                                    {village?.map((item) => (
                                        <option value={item.id} >{item.name}</option>
                                    ))}
                                </select>

                                <select value={tpsId} data-te-select-init data-te-select-filter="true" onChange={handleTps}>
                                    <option value="0" className="text-sm">No TPS</option>
                                    {tps?.map((item) => (
                                        <option value={item.tps_number} >{item.tps_number}</option>
                                    ))}
                                </select>
                            </div>

                            {/* <!--Modal footer--> */}
                            <div
                                class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                <button
                                    type="button"
                                    class="inline-block rounded bg-yellow-400 px-6 pb-2 pt-2.5 text-sm uppercase leading-normal text-yellow-700 transition duration-150 ease-in-out hover:bg-yellow-accent-100 focus:bg-yellow-accent-100 focus:outline-none focus:ring-0 active:bg-yellow-accent-200 font-semibold"
                                    data-te-modal-dismiss
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Mobile Filter */}

                {/* Desktop Filter */}
                <div className="w-full hidden md:flex justify-between items-center px-5 py-3">
                    <div className="flex items-center space-x-3 text-sx">
                        <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                            <option value="3205">KABUPATEN GARUT</option>
                            <option value="3206">KABUPATEN TASIKMALAYA</option>
                            <option value="3278">KOTA TASIKMALAYA</option>
                        </select>
                        <select value={districtId} data-te-select-init data-te-select-filter="true" onChange={handleDistrict}>
                            <option value="" className="text-sm">Kecamatan</option>
                            {district?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={villageId} data-te-select-init data-te-select-filter="true" onChange={handleVillage}>
                            <option value="" className="text-sm">Kelurahan</option>
                            {village?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={tpsId} data-te-select-init data-te-select-filter="true" onChange={handleTps}>
                            <option value="0" className="text-sm">No TPS</option>
                            {tps?.map((item) => (
                                <option value={item.tps_number} >{item.tps_number}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* End Desktop Filter */}

                <div className="w-full p-5 overflow-x-auto relative">
                    {/* Desktop Tabel */}
                    <table className="min-w-max w-full hidden md:block">
                        <thead className="">
                            <tr className="text-sm w-full">
                                <th className="w-16 py-2 aspect-square border-2 ">No</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">No Kartu Keluarga</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Nik</th>
                                <th className="w-72 py-2 aspect-square border-2 px-2 text-start overflow-auto">Nama</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kota / Kabupaten</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kecamatan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Desa / Kelurahan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">TPS</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading === false ?
                                dpt.map((dataDpt) => (
                                    <tr className={`text-sm ${number % 2 === 1 ? 'bg-white hover:bg-gray-400' : 'bg-gray-200 hover:bg-gray-400'}`}>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer text-center">{number++}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer bg-gray px-3 w-fit">{dataDpt.nkk}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataDpt.nik}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataDpt.nama}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataDpt.alamat.kabupaten}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataDpt.alamat.kecamatan}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataDpt.alamat.kelurahan}</td>
                                        <td onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer px-3 py-2 text-center">TPS {dataDpt.no_tps}</td>
                                        <td className="text-center px-3 py-2">
                                            {/* <form onSubmit={() => handleDeleteDpt(dataDpt.id)}> */}
                                            <button
                                                type="button"
                                                onClick={() => handleDeleteDpt(dataDpt.id)}
                                                data-te-ripple-init
                                                data-te-ripple-color="light"
                                                data-te-toggle="popconfirm"
                                                data-te-popconfirm-mode="inline"
                                                data-te-message="Apakah Anda yakin akan menghapus data ini..?"
                                                data-te-cancel-text="Tidak"
                                                data-te-ok-text="Ya"
                                                className="confirm-delete text-red-500">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            {/* </form> */}
                                        </td>
                                    </tr>
                                )) : Array.from({ length: 20 }, (_, j) => (
                                    <tr key={j} className="animate-pulse w-full">
                                        {Array.from({ length: 9 }, (_, i) => (
                                            <td key={i} className="px-3 py-2">
                                                <div className="h-5 bg-slate-200 rounded"></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {/* End Desktop Header */}

                    {/* Mobile List Tile */}
                    <div className="space-y-3 md:hidden w-full">
                        {isLoading === false ?
                            dpt.map((dataDpt) => (
                                <div onClick={() => navigate(`/dashboard/dpt/detail/${dataDpt.id}`)} className="cursor-pointer w-full h-16 bg-white drop-shadow-md flex items-center justify-between text-sm rounded-md px-3">
                                    <div>
                                        <p className="font-semibold">{dataDpt.nama}</p>
                                        <p>{dataDpt.nik}</p>
                                    </div>
                                    <div className="flex flex-col items-center justify-center">
                                        <p>TPS</p>
                                        <p>{dataDpt.no_tps}</p>
                                    </div>
                                </div>
                            )) : Array.from({ length: 20 }, (_, j) => (
                                <div div key={j} className="animate-pulse w-full h-16 bg-white drop-shadow-md flex items-center justify-between text-sm rounded-md px-3" >
                                    <div className="space-y-3">
                                        <div className="rounded-full w-32 h-2 bg-slate-200"></div>
                                        <div className="rounded-full w-32 h-2 bg-gray-200"></div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center space-y-3">
                                        <div className="rounded-full w-3 h-2 bg-gray-200"></div>
                                        <div className="rounded-full w-5 h-2 bg-gray-200"></div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {/* End Mobile List Tile */}

                    {/* Previous & Next Page */}
                    <div className="w-full bg-white h-3"></div>
                    <div className="w-full flex items-center justify-end space-x-3 py-5 bg-gray-200 px-5">
                        <button type="button"
                            onClick={handlePreviousPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                            <span className="ml-3">Kembali</span>
                        </button>
                        <p className="text-xl font-bold ">{currentPage}</p>
                        <button type="button"
                            onClick={handleNextPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <span className="mr-3">Selanjutnya</span>
                            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
                        </button>
                    </div>
                    {/* End Previous & Next Page */}

                </div >
            </LayoutSuperAdmin >
        </>
    )
}

export default Dpt