import React from "react"
import ImageNotFound from "../images/not_found_page.png"


const NotFoundPage = () => {
    const handleBack = () => {
        // router.back ? router.back() : router.push('/')
        window.history.back()
    }
    return (
        <div className="h-screen w-full flex items-center justify-center">
            <img src={ImageNotFound} alt="Not Found Page - Geulis Harmonis" className="md:h-full w-full aspect-video object-contain"></img>
            <button type="button" onClick={handleBack} className="absolute px-5 py-2 right-10 bottom-20 bg-yellow-600 rounded-md font-semibold hover:bg-yellow-500 transition-colors">
                Kembali
            </button>
        </div>
    )
}

export default NotFoundPage