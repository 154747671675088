import apiService from "./api_service";

export const getBanner = async (accessToken, roleName) => {
    try {

        const response = await apiService.get(`${roleName}/banner`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showBanner = async (accessToken, roleName, bannerId) => {
    try {
    
        const response = await apiService.get(`${roleName}/banner/show/${encodeURIComponent(bannerId)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const postBanner = async (accessToken, roleName, image, detail) => {
    try {

        const body = {
            'image' : image,
            'detail' : detail
        }

        const response = await apiService.post(`${roleName}/banner/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const updateBanner = async (accessToken, roleName, bannerId, image, detail) => {
    try {

        const body = {
            'image' : image,
            'detail' : detail
        }

        const response = await apiService.post(`${roleName}/banner/update/${bannerId}`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};