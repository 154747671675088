import { Helmet } from "react-helmet"
import Icon from "../../images/tangan_bendera.png"
import Navbar from "../../components/navbar"

const LandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Sparta 24</title>
            </Helmet>
            <Navbar></Navbar>
            <div id="welcome" className="w-full h-screen bg-white flex flex-col md:flex-row item-center pt-20 md:pt-0">
                <div className="md:w-1/2 md:h-full text-black flex flex-col justify-center items-center md:items-start px-5 md:px-10 order-2 md:order-1">
                    <p className="text-3xl md:text-5xl font-bold mb-5">SPARTA 24</p>
                    <p className="text-xs md:text-base text-center md:text-start lg:text-start">
                        Sparta adalah sistem yang tepat untuk membantu kandidat meraih kemenangan dalam pemilu. Sistem ini dilengkapi dengan berbagai fitur yang dapat membantu kandidat untuk mengenal lebih baik konstituennya, menyusun strategi kampanye yang efektif, menyampaikan pesan kampanye yang tepat sasaran, dan melacak hasil kampanye. Dengan menggunakan Sparta, kandidat dapat meningkatkan peluangnya untuk memenangkan pemilu.
                    </p>
                    <a href="https://s.id/sparta24" className="px-5 py-2 rounded-md bg-yellow-400 w-fit font-semibold mt-5 md:mt-10 mx-auto md:mx-0" download="./sparta.apk">
                        Download Aplikasi
                    </a>
                </div>
                <div className="md:w-1/2 md:h-full flex items-center justify-center md:pt-16 order-1 md:order-2 text-xs md:text-base">
                    <img src={Icon} alt="Sparta Icon" className="w-[70%] md:h-[80%] aspect-square object-contain mb-5 md:mb-0"></img>
                </div>
            </div>
        </>
    )
}

export default LandingPage