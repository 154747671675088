import apiService from "./api_service";

export const getVoter = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        if (roleName === 'relawan-sparta' || roleName === 'relawan-caleg') {
            const response = await apiService.get(`${roleName}/voter?page=${page}&type=web`, {
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response
        } else {
            const response = await apiService.get(`${roleName}/voter?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&tps_number=${tpsId === 0 ? null : tpsId}`, {
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json'
                }
            });
            return response
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchVoter = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page, keyword) => {
    try {
        const response = await apiService.get(`${roleName}/voter/search?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&tps_number=${tpsId === 0 ? null : tpsId}&keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showVoter = async (accessToken, roleName, voterId) => {
    try {
        const response = await apiService.get(`${roleName}/voter/show/${voterId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const postVoter = async (accessToken, roleName, nik) => {
    try {
        const body = {
            'nik': nik
        }
        const response = await apiService.post(`${roleName}/voter/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const importVoter = async (accessToken, roleName, file) => {
    try {
        if (!file) {
            console.error('File belum dipilih.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await apiService.post(`${roleName}/voter/import`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const exportVoter = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        const response = await apiService.get(`${roleName}/voter/export?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const deleteVoter = async (accessToken, roleName, voterId) => {
    try {
        const response = await apiService.post(`${roleName}/voter/delete/${encodeURIComponent(voterId)}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }

};
