import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import TemplateText from "../../../components/template_text"
import {
    Collapse,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { showDpt } from "../../../services/dpt_service"

const DetailDpt = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [dpt, setDpt] = useState(null)


    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken == null) {
            navigate('/login')
        } else {
            showDpt(accessToken, roleName, id).then((response) => {
                if (response && response.status === 200) {
                    setDpt(response.data.data)
                } else {
                    // confirm('dsdsd')
                }
            });
        }

        initTE({ Collapse })

    }, [id, navigate]);
    return (
        <>
            <Helmet>
                <title>Detail Dpt</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full z-30">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-5 md:px-10 text-white">
                        <button type="button" onClick={() => navigate('/dashboard/dpt')}>
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                        </button>
                        <p class="text-xl font-semibold text-white text-center">Detail Dpt</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">

                        </button>
                    </div>
                </div>

                <div className="w-full flex flex-col md:flex-row items-start p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3">
                    <div className="w-full md:w-1/2 space-y-3">
                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                            <p className="font-semibold">Detail Dpt</p>
                        </div>
                        <TemplateText name="No Kartu Keluarga" value={dpt?.nkk}></TemplateText>
                        <TemplateText name="NIK" value={dpt?.nik}></TemplateText>
                        <TemplateText name="Nama Lengkap" value={dpt?.nama}></TemplateText>
                        <TemplateText name="Tempat Lahir" value={dpt?.tempat_lahir}></TemplateText>
                        <TemplateText name="Tanggal Lahir" value={dpt?.tanggal_lahir}></TemplateText>
                        <TemplateText name="Usia" value={dpt?.usia}></TemplateText>
                        {/* <TemplateText name="Status Perkawinan" value={dpt?.status_perkawinan}></TemplateText> */}
                        <TemplateText name="No TPS" value={dpt?.no_tps}></TemplateText>
                        <TemplateText name="Role" value={dpt?.role_name}></TemplateText>
                    </div>
                    <div className="w-full md:w-1/2 space-y-3">
                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                            <p className="font-semibold">Alamat Dpt</p>
                        </div>
                        <TemplateText name="Provinsi" value={dpt?.alamat?.provinsi}></TemplateText>
                        <TemplateText name="Kabupaten" value={dpt?.alamat?.kabupaten}></TemplateText>
                        <TemplateText name="Kecamatan" value={dpt?.alamat?.kecamatan}></TemplateText>
                        <TemplateText name="Kecamatan" value={dpt?.alamat?.kelurahan}></TemplateText>
                        <TemplateText name="rt" value={dpt?.alamat?.rt}></TemplateText>
                        <TemplateText name="rw" value={dpt?.alamat?.rw}></TemplateText>
                        <TemplateText name="detail" value={dpt?.alamat?.detail}></TemplateText>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default DetailDpt