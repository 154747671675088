import apiService from "./api_service";

export const getCalegType = async (accessToken, roleName,) => {
    try {

        const response = await apiService.get(`${roleName}/caleg-type`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showCalegType = async (accessToken, roleName, calegType) => {
    try {

        const response = await apiService.get(`${roleName}/caleg-type/show/${encodeURIComponent(calegType)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};