import React, { useEffect, useState } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logo from "../images/sparta.png"

const Navbar = () => {
    const [accessToken, setAccesToken] = useState('')

    const handleScroll = (e) => {
        e.preventDefault();
        const href = e.currentTarget.href;
        const targetId = href.replace(/.*#/, "");
        const elem = document.getElementById(targetId);
        elem?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const [isCollapse, setIsCollapse] = useState(true);
    const toggleCollapse = () => {
        setIsCollapse(!isCollapse)
    }

    useEffect(() => {
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setAccesToken(accessToken)
        
    }, [])
    return (
        <>
            <nav className="w-full text-white fixed z-50 shadow-md">
                <div className="bg-white/50 backdrop-blur-sm w-full h-16 flex items-center justify-between px-5 lg:px-10">

                    <div className='md:w-[20%]  text-2xl font-semibold text-yellow-600'>
                        <img src={Logo} alt='Logo SPARTA 24' className='w-14 aspect-square object-contain '></img>
                        
                    </div>
                    <div className="hidden lg:flex space-x-3 items-center justify-center font-semibold text-black w-[60%]">
                        <a href="#welcome" onClick={handleScroll}>Home</a>
                        <a href="#tentang" onClick={handleScroll}>Tentang</a>
                        <a href="#fitur" onClick={handleScroll}>Fitur</a>
                        {/* <a href="#personalSkillSection" onClick={handleScroll}>Personal Skill</a>
                        <a href="#portfolioSection" onClick={handleScroll}>Portfolio</a>
                        <a href="#blogSection" onClick={handleScroll}>Blog</a> */}
                        <a href="#footer" onClick={handleScroll}>Contact</a>
                    </div>
                    <div className='hidden text-black md:flex items-center justify-end space-x-3 w-[20%]'>
                        {accessToken === "Bearer null" || accessToken == null ?
                            <a href='/login' className='bg-yellow-400 w-32 py-2 text-center font-semibold rounded-md hover:bg-yellow-500 transition-all duration-300'>
                                Login
                            </a> :
                            <a href='/dashboard' className='bg-yellow-400 w-32 py-2 text-center font-semibold rounded-md hover:bg-yellow-500 transition-all duration-300'>
                                Dashboard
                            </a>}
                    </div>
                    <button type='button' onClick={toggleCollapse} className='lg:hidden flex items-center justify-center text-2xl relative text-black'>
                        <FontAwesomeIcon icon={faBars} className={isCollapse ? 'scale-100 rotate-0 transition-all duration-500' : 'scale-0 rotate-180 transition-all duration-300'} />
                        <FontAwesomeIcon icon={faClose} className={isCollapse ? 'absolute scale-0 -rotate-180 transition-all duration-300' : 'absolute scale-100 text-red-500 rotate-0 transition-all duration-500'} />
                    </button>
                </div>
                <div className={isCollapse ? 'w-full h-0 px-5 flex flex-col items-center space-y-3 overflow-hidden transition-all duration-1000 rounded-b-2xl bg-white backdrop-blur-sm' : 'lg:hidden w-full px-5 flex flex-col items-center space-y-3 h-[350px] transition-all duration-1000 overflow-hidden rounded-b-2xl bg-white/50 backdrop-blur-sm text-black font-semibold'}>
                    <div className='w-full h-1 bg-gray-900 rounded-full mb-3'></div>
                    <a href="#welcome" onClick={handleScroll}>Home</a>
                    <a href="#tentang" onClick={handleScroll}>Tentang</a>
                    <a href="#fature" onClick={handleScroll}>Fitur</a>
                    <div className=' text-black flex space-x-3'>
                        {accessToken === "Bearer null" || accessToken == null ?
                            <a href='/login' className='bg-yellow-400 w-32 py-2 text-center font-semibold rounded-md hover:bg-yellow-500 transition-all duration-300'>
                                Login
                            </a> :
                            <a href='/dashboard' className='bg-yellow-400 w-32 py-2 text-center font-semibold rounded-md hover:bg-yellow-500 transition-all duration-300'>
                                Dashboard
                            </a>}
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar