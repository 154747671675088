import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import TemplateText from "../../../components/template_text"
import {
    Collapse,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { showVoter } from "../../../services/voter_service"

const DetailVoter = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [voter, setVoter] = useState(null)


    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken == null) {
            navigate('/login')
        } else {
            showVoter(accessToken, roleName, id).then((response) => {
                if (response && response.status === 200) {
                    setVoter(response.data.data)
                } else {
                    setVoter([])
                }
            });
        }

        initTE({ Collapse })
    }, [id, navigate]);
    return (
        <>
            <Helmet>
                <title>Detail Voter</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full z-30">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-5 md:px-10 text-white">
                        <a href="/dashboard/voter" className="flex items-center space-x-3">
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                            <p className="hidden md:block font-semibold">Kembali</p>
                        </a>
                        <p class="text-xl font-semibold text-white text-center">Detail Voter</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">

                        </button>
                    </div>
                </div>

                <div className="w-full flex flex-col md:flex-row items-start p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3">
                    <div className="w-full md:w-1/2 space-y-3">
                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                            <p className="font-semibold">Detail Voter</p>
                        </div>
                        <TemplateText name="No Kartu Keluarga" value={voter?.nkk}></TemplateText>
                        <TemplateText name="NIK" value={voter?.nik}></TemplateText>
                        <TemplateText name="Nama Lengkap" value={voter?.nama}></TemplateText>
                        <TemplateText name="Tempat Lahir" value={voter?.tempat_lahir}></TemplateText>
                        <TemplateText name="Tanggal Lahir" value={voter?.tanggal_lahir}></TemplateText>
                        <TemplateText name="Usia" value={voter?.usia}></TemplateText>
                        {/* <TemplateText name="Status Perkawinan" value={voter?.status_perkawinan}></TemplateText> */}
                        <TemplateText name="No TPS" value={voter?.no_tps}></TemplateText>
                    </div>
                    <div className="w-full md:w-1/2 space-y-3">
                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                            <p className="font-semibold">Alamat Voter</p>
                        </div>
                        <TemplateText name="Provinsi" value={voter?.alamat?.provinsi}></TemplateText>
                        <TemplateText name="Kabupaten" value={voter?.alamat?.kabupaten}></TemplateText>
                        <TemplateText name="Kecamatan" value={voter?.alamat?.kecamatan}></TemplateText>
                        <TemplateText name="Kecamatan" value={voter?.alamat?.kelurahan}></TemplateText>
                        <TemplateText name="rt" value={voter?.alamat?.rt}></TemplateText>
                        <TemplateText name="rw" value={voter?.alamat?.rw}></TemplateText>
                        <TemplateText name="detail" value={voter?.alamat?.detail}></TemplateText>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default DetailVoter