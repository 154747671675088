import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landing_page';
import Login from './pages/login';
import NotFoundPage from './pages/404';
import Dashboard from './pages/dashboard';
import Caleg from './pages/dashboard/caleg';
import CalegList from './pages/dashboard/caleg/caleg_list';
import CalegDetail from './pages/dashboard/caleg/caleg_detail';
import Dpt from './pages/dashboard/dpt';
import Admin from './pages/dashboard/admin';
import Relawan from './pages/dashboard/relawan';
import RelawanDetail from './pages/dashboard/relawan/detail';
import PresensiVoter from './pages/dashboard/voter/presensi';
import FormC1 from './pages/dashboard/from-c1';
import Setting from './pages/dashboard/setting';
import Voter from './pages/dashboard/voter';
import DetailDpt from './pages/dashboard/dpt/detail_dpt';
import DetailVoter from './pages/dashboard/voter/detail_voter';
import CalegAdmin from './pages/dashboard/caleg/caleg_admin';
import CalegRelawan from './pages/dashboard/caleg/caleg_relawan';
import CalegVoter from './pages/dashboard/caleg/caleg_voter';
import ForbiddenPage from './pages/403';
import CalegRelawanDetail from './pages/dashboard/caleg/caleg_relawan_detail';

function App() {
  return (

    <Router >
      <Routes>
        <Route path='/' element={<LandingPage />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/403' element={<ForbiddenPage />}></Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/dashboard/caleg' element={<Caleg />}></Route>
        <Route path='/dashboard/caleg/admin/list' element={<CalegAdmin />}></Route>
        <Route path='/dashboard/caleg/:caleg_id/relawan/list' element={<CalegRelawan />}></Route>
        <Route path='/dashboard/caleg/:caleg_id/relawan/detail/:relawan_id' element={<CalegRelawanDetail />}></Route>
        <Route path='/dashboard/caleg/voter/list' element={<CalegVoter />}></Route>
        <Route path='/dashboard/caleg/:type_id' element={<CalegList />}></Route>
        <Route path='/dashboard/caleg/:type_id/detail/:caleg_id' element={<CalegDetail />}></Route>
        <Route path='/dashboard/dpt' element={<Dpt />}></Route>
        <Route path='/dashboard/dpt/detail/:id' element={<DetailDpt />}></Route>
        <Route path='/dashboard/admin' element={<Admin />}></Route>
        <Route path='/dashboard/relawan' element={<Relawan />}></Route>
        <Route path='/dashboard/relawan/detail/:id' element={<RelawanDetail />}></Route>
        <Route path='/dashboard/voter/' element={<Voter />}></Route>
        <Route path='/dashboard/voter/detail/:id' element={<DetailVoter />}></Route>
        <Route path='/dashboard/presensi/voter/' element={<PresensiVoter />}></Route>
        <Route path='/dashboard/form-c1' element={<FormC1 />}></Route>
        <Route path='/dashboard/pengaturan' element={<Setting />}></Route>
        <Route path='*' element={<NotFoundPage />}></Route>
      </Routes>
    </Router >
  );
}

export default App;
