// import axios from "axios";

import axios from "./axios";

const baseURL = 'https://web.sparta-24.com/api'

const apiService = axios.create({
  baseURL,
});

export default apiService