import CalegSuperAdmin from "./views/caleg_super_admin"

const Caleg = () => {
    return (
            <>
                <CalegSuperAdmin></CalegSuperAdmin>
            </>
    )
}

export default Caleg