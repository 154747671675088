import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../../components/layouts/layout_super_admin"
import { useEffect, useState } from "react"
import { getCalegType } from "../../../../services/caleg_type_service"

const CalegSuperAdmin = () => {

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [calegType, setCalegType] = useState([])

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        getCalegType(accessToken, roleName).then((response) => {
            if (response && response.status === 200) {
                setCalegType(response.data.data)
            } else {
                setCalegType([])
            }
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Caleg - Sparta 24</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 w-full  h-14 bg-yellow-400">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-10 text-white">
                        <div></div>
                        <p class="text-xl font-semibold text-white text-center">CALEG - SPARTA 24</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-5 p-3 md:p-5">
                    {calegType.map((data) => (
                        <a href={`/dashboard/caleg/${data.id}`}>
                            <div className="w-full aspect-video flex flex-col items-center justify-center bg-yellow-400 rounded-2xl md:space-y-3 hover:scale-105 transition-all duration-500">
                                <p className="font-bold text-base md:text-2xl">{data.name}</p>
                                <p className="font-semibold space-x-3">
                                    <span className=" text-base md:text-2xl">{data.total_caleg}</span>
                                    <span>Caleg</span>
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default CalegSuperAdmin