import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getRelawanVoter, showRelawan } from "../../../services/relawan_service"
import TemplateText from "../../../components/template_text"
import {
    Collapse,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons"

const CalegRelawanDetail = () => {
    const navigate = useNavigate()
    const { caleg_id } = useParams()
    const { relawan_id } = useParams()

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [relawan, setRelawan] = useState(null)


    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken == null) {
            navigate('/login')
        } else {
            showRelawan(accessToken, roleName, relawan_id).then((response) => {
                if (response && response.status === 200) {
                    setRelawan(response.data.data)
                } else {
                    // confirm('dsdsd')
                }
            });

            getRelawanVoter(accessToken, roleName, relawan_id).then((response) => {
                console.log(response);
            })
        }

        initTE({ Collapse })

    }, [caleg_id, relawan_id, navigate]);
    return (
        <>
            <Helmet>
                <title>Detail Relawan</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full z-30">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-5 md:px-10 text-white">
                        <button type="button" onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/list`)}>
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                            <p className="font-semibold ml-3">Kembali</p>
                        </button>
                        <p class="text-xl font-semibold text-white text-center">Detail Relawan</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">
                            
                        </button>
                    </div>
                </div>

                {/* Voter By Relawan */}

                <div id="accordionExample">
                    <div
                        class="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                        <h2 class="mb-0" id="headingOne">
                            <button
                                class="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                                type="button"
                                data-te-collapse-init
                                data-te-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne">
                                Data Relawan
                                <span
                                    class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            class="!visible"
                            data-te-collapse-item
                            data-te-collapse-show
                            aria-labelledby="headingOne"
                            data-te-parent="#accordionExample">
                            <div class="md:px-5 md:py-4">
                                <div className="w-full flex flex-col md:flex-row items-start p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3">
                                    <div className="w-full md:w-1/2 space-y-3">
                                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                                            <p className="font-semibold">Detail Relawan</p>
                                        </div>
                                        <TemplateText name="No Kartu Keluarga" value={relawan?.nkk}></TemplateText>
                                        <TemplateText name="NIK" value={relawan?.nik}></TemplateText>
                                        <TemplateText name="Nama Lengkap" value={relawan?.nama}></TemplateText>
                                        <TemplateText name="Tempat Lahir" value={relawan?.tempat_lahir}></TemplateText>
                                        <TemplateText name="Tanggal Lahir" value={relawan?.tanggal_lahir}></TemplateText>
                                        <TemplateText name="Usia" value={relawan?.usia}></TemplateText>
                                        {/* <TemplateText name="Status Perkawinan" value={relawan?.status_perkawinan}></TemplateText> */}
                                        <TemplateText name="No TPS" value={relawan?.no_tps}></TemplateText>
                                        <TemplateText name="Role" value={relawan?.role_name}></TemplateText>
                                    </div>
                                    <div className="w-full md:w-1/2 space-y-3">
                                        <div className="w-full px-3 py-2 bg-gray-50 drop-shadow-md rounded-t-md">
                                            <p className="font-semibold">Alamat Relawan</p>
                                        </div>
                                        <TemplateText name="Provinsi" value={relawan?.alamat?.provinsi}></TemplateText>
                                        <TemplateText name="Kabupaten" value={relawan?.alamat?.kabupaten}></TemplateText>
                                        <TemplateText name="Kecamatan" value={relawan?.alamat?.kecamatan}></TemplateText>
                                        <TemplateText name="Kecamatan" value={relawan?.alamat?.kelurahan}></TemplateText>
                                        <TemplateText name="rt" value={relawan?.alamat?.rt}></TemplateText>
                                        <TemplateText name="rw" value={relawan?.alamat?.rw}></TemplateText>
                                        <TemplateText name="detail" value={relawan?.alamat?.detail}></TemplateText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                        <h2 class="accordion-header mb-0" id="headingThree">
                            <button
                                class="group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] [&[data-te-collapse-collapsed]]:rounded-b-[15px] [&[data-te-collapse-collapsed]]:transition-none"
                                type="button"
                                data-te-collapse-init
                                data-te-collapse-collapsed
                                data-te-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree">
                                Daftar Voter By Relawan
                                <span
                                    class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            class="!visible hidden"
                            data-te-collapse-item
                            aria-labelledby="headingThree"
                            data-te-parent="#accordionExample">
                            <div class="px-5 py-4">
                                <strong>This is the third item's accordion body.</strong> It is
                                hidden by default, until the collapse plugin adds the appropriate
                                classes that we use to style each element. These classes control
                                the overall appearance, as well as the showing and hiding via CSS
                                transitions. You can modify any of this with custom CSS or
                                overriding our default variables. It's also worth noting that just
                                about any HTML can go within the <code>.accordion-body</code>,
                                though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default CalegRelawanDetail