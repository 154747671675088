import apiService from "./api_service";

export const getDpt = async (accessToken, roleName, regencyId, districtId, villageId, tpsNumber, page,) => {
    try {
        let name = 'super-admin'
        switch (roleName) {
            case "SUPER ADMIN":
                    name = 'super-admin'        
                break;

            case "CALEG":
                    name = 'caleg'        
                break;
        
            default:
                break;
        }
        const response = await apiService.get(`${name}/dpt?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&tps_number=${tpsNumber === 0 ? null : tpsNumber}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchDpt = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page, keyword) => {
    try {
        let name = 'super-admin'
        switch (roleName) {
            case "SUPER ADMIN":
                    name = 'super-admin'        
                break;

            case "CALEG":
                    name = 'caleg'        
                break;
        
            default:
                break;
        }
        // const response = await apiService.get(`${name}/dpt/search?keyword=melani}`, {
        const response = await apiService.get(`${name}/dpt/search?keyword=${keyword}&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showDpt = async (accessToken, roleName, dptId) => {
    try {
        let name = 'super-admin'
        switch (roleName) {
            case "SUPER ADMIN":
                    name = 'super-admin'        
                break;

            case "CALEG":
                    name = 'caleg'        
                break;
        
            default:
                break;
        }

        const response = await apiService.get(`${name}/dpt/show/${encodeURIComponent(dptId)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const exportDpt = async (accessToken, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        const response = await apiService.get(`/dpt/export?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const deleteDpt = async (accessToken, dptId) => {
    try {
        const response = await apiService.post(`super-admin/dpt/delete/${dptId}`,{}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
            return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }

};
