
import apiService from "./api_service";

export const getRelawan = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        const response = await apiService.get(`${roleName}/relawan?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&tps_number=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchRelawan = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page, keyword) => {
    try {
        const response = await apiService.get(`${roleName}/relawan/search?type=web&keyword=${keyword}&page=${page}&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&tps_number=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showRelawan = async (accessToken, roleName, relawanId) => {
    try {
        const response = await apiService.get(`${roleName}/relawan/show/${encodeURIComponent(relawanId)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const postRelawan = async (accessToken, roleName, nik) => {
    try {
    
        const body = {
            'nik': nik
        }
        const response = await apiService.post(`${roleName}/relawan/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const importRelawan = async (accessToken, roleName, file) => {
    try {
        if (!file) {
            console.error('File belum dipilih.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await apiService.post(`${roleName}/relawan/import`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const exportRelawan = async (accessToken, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        const response = await apiService.get(`/relawan/export?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const deleteRelawan = async (accessToken, roleName, relawanId) => {
    try {
        const response = await apiService.post(`${roleName}/relawan/delete/${encodeURIComponent(relawanId)}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getRelawanVoter = async (accessToken, roleName, relawanId,) => {
    try {
        const response = await apiService.get(`${roleName}/relawan/${encodeURIComponent(relawanId)}/voter`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};