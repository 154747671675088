import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useEffect, useState } from "react"
import { deleteCaleg, showCaleg } from "../../../services/caleg_service";
import { useNavigate, useParams } from "react-router-dom";
import TemplateText from "../../../components/template_text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft, faPerson, faTrash, faUserTie } from "@fortawesome/free-solid-svg-icons";
import {
    Select,
    Dropdown,
    Modal,
    Input,
    Ripple,
    initTE,
} from "tw-elements";

const CalegDetail = () => {
    const navigate = useNavigate()
    const { type_id } = useParams()
    const { caleg_id } = useParams()

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [caleg, setCaleg] = useState(null)

    const handleDeleteCaleg = () => {
        deleteCaleg(accessToken, roleName, caleg_id).then((response) => {
            navigate(`/dashboard/caleg/${type_id}`)
        })
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)
        showCaleg(accessToken, roleName, caleg_id).then((response) => {
            if (response && response.status === 200) {
                setCaleg(response.data.data)
            } else {
                setCaleg(null)
            }
        });

        initTE({ Select, Modal, Input, Dropdown, Ripple })

    }, [caleg_id, navigate]);
    return (
        <>
            <Helmet>
                <title>Detail Caleg</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-10 text-white">
                        <a href={`/dashboard/caleg/${type_id}`}>
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                            <span className="ml-3 font-semibold">Kembali</span>
                        </a>
                        <p class="text-xl font-semibold text-white text-center">Detail Caleg</p>
                        <div class="relative" data-te-dropdown-ref>
                            <button
                                className="confirm-delete flex items-center space-x-3 px-3 py-2 bg-red-400 hover:bg-red-500 transition-all text-white font-semibold rounded-md"
                                type="button"
                                id="dropdownMenuButton1"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light">
                                <FontAwesomeIcon icon={faTrash} />
                                <p>Hapus Caleg</p>
                            </button>
                            <ul
                                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-yellow-50 bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton1"
                                data-te-dropdown-menu-ref>
                                <div className="px-10 space-y-3 py-3 w-72 text-sm">
                                    <p className="text-center text-black">Apakah Anda yakin <br />akan menghapus Caleg ini..?</p>
                                    <p className="text-center text-black">Jika Anda menghapusnya, maka semua data yang berhubungan dengan Caleg ini akan terhapus juga..!</p>
                                    <div className="flex items-center justify-evenly space-x-3 font-semibold">
                                        <button
                                            type="button"
                                            className="h-8 w-24 bg-green-400 hover:bg-green-500 rounded-md"
                                        >
                                            Tidak

                                        </button>
                                        <button
                                            type="button"
                                            onClick={()=>handleDeleteCaleg()}
                                            className="h-8 w-24 bg-red-400 hover:bg-red-500 rounded-md"
                                        >
                                            Ya
                                        </button>
                                    </div>
                                </div>

                            </ul>
                        </div>
                    </div>

                </div>


                <div className="w-full flex flex-col md:flex-row items-start p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3">
                    <div className="w-[80%] md:w-[30%] mx-auto">
                        {caleg?.image == null ?
                            <div className="w-full aspect-[12/16] bg-yellow-100 rounded-md drop-shadow-md flex items-center justify-center">
                                <FontAwesomeIcon icon={faUserTie} className="w-[80%] h-[80%] object-contain"></FontAwesomeIcon>
                            </div> :
                            <img src={`http://web.sparta-24.com/${caleg?.image}`} alt="Caleg - SPARTA 24" className="w-full aspect-[12/16] rounded-md object-cover object-center drop-shadow-lg" />
                        }
                    </div>
                    <div className="w-full md:w-[70%] space-y-3 ">
                        <TemplateText name="Nama Caleg" value={caleg?.name}></TemplateText>
                        <TemplateText name="Email Caleg" value={caleg?.email}></TemplateText>
                        <TemplateText name="Nama Partai" value={caleg?.nama_partai}></TemplateText>
                        <TemplateText name="No Urut" value={caleg?.no_urut}></TemplateText>
                        <div className="grid grid-cols-3 gap-3 text-sm md:text-base">
                            <div onClick={() => navigate('/dashboard/caleg/admin/list')} className="cursor-pointer w-full aspect-video bg-yellow-100 hover:bg-yellow-400 drop-shadow-md rounded-md flex flex-col items-center justify-center font-semibold space-y-0 md:space-y-3 transition-all duration-300">
                                <p className="text-base md:text-2xl">{caleg?.jumlah_admin}</p>
                                <p >Admin</p>
                            </div>
                            <div onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/list`)} className="cursor-pointer w-full aspect-video bg-yellow-100 hover:bg-yellow-400 drop-shadow-md rounded-md flex flex-col items-center justify-center font-semibold space-y-0 md:space-y-3 transition-all duration-300">
                                <p className="text-base md:text-2xl">{caleg?.jumlah_relawan}</p>
                                <p >Relawan</p>
                            </div>
                            <div onClick={() => navigate('/dashboard/caleg/voter/list')} className="cursor-pointer w-full aspect-video bg-yellow-100 hover:bg-yellow-400 drop-shadow-md rounded-md flex flex-col items-center justify-center font-semibold space-y-0 md:space-y-3 transition-all duration-300">
                                <p className="text-base md:text-2xl">{caleg?.jumlah_voter}</p>
                                <p >Voter</p>
                            </div>
                        </div>
                    </div>
                </div>

            </LayoutSuperAdmin >
        </>
    )
}

export default CalegDetail