import apiService from "./api_service";

export const getAdmin = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page,) => {
    try {

        const response = await apiService.get(`${roleName}/admin?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchAdmin = async (accessToken, roleName, regencyId, districtId, villageId, tpsId, page,) => {
    try {

        const response = await apiService.get(`${roleName}/admin?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showAdmin = async (accessToken, roleName, adminId) => {
    try {
    
        const response = await apiService.get(`${roleName}/admin/show/${encodeURIComponent(adminId)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const postAdmin = async (accessToken, roleName, name, email, regency_id, district_id, village_id,) => {
    try {

        const body = {
            'name' : name,
            'email' : email,
            'regency_id' : regency_id,
            'district_id' : district_id,
            'village_id' : village_id
        }

        const response = await apiService.post(`${roleName}/admin/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const deleteAdmin = async (accessToken, roleName, id) => {
    try {

        const response = await apiService.post(`${roleName}/admin/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const exportAdmin = async (accessToken, regencyId, districtId, villageId, tpsId, page,) => {
    try {
        const response = await apiService.get(`/admin/export?page=${page}&type=web&province_id=32&regency_id=${regencyId === 0 ? null : regencyId}&district_id=${districtId === 0 ? null : districtId}&village_id=${villageId === 0 ? null : villageId}&no_tps=${tpsId === 0 ? null : tpsId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};