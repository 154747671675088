import React from "react"
import ImageForbidden from "../images/forbidden.jpg"


const ForbiddenPage = () => {
    const handleBack = () => {
        window.history.back()
    }
    return (
        <div className="h-screen w-full flex flex-col items-center justify-center space-x-3">
            <img src={ImageForbidden} alt="FORBIDDEN - SPARTA 24" className="w-full md:h-[70%] aspect-video object-contain mx-auto"></img>
            <p className="text-4xl font-semibold text-center">FORBIDDEN</p>
            <p className="text-7xl font-semibold text-center">401</p>
            <button type="button" onClick={handleBack} className="absolute px-5 py-2 md:right-10 bottom-20 bg-yellow-400 rounded-md font-semibold hover:bg-yellow-500 transition-colors">
                Kembali
            </button>
        </div>
    )
}

export default ForbiddenPage