import apiService from "./api_service";

export const getCalegRelawan = async (accessToken, roleName, calegId) => {
    try {

        const response = await apiService.get(`${roleName}/caleg/${encodeURIComponent(calegId)}/relawan?type=web`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchCalegRelawan = async (accessToken, roleName, calegId, keyword) => {
    try {

        const response = await apiService.get(`${roleName}/caleg/${encodeURIComponent(calegId)}/relawan/search?keyword=${keyword}?type=web`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
