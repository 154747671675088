import apiService from "./api_service";

export const getCaleg = async (accessToken, roleName, calegTypeId) => {
    try {

        const response = await apiService.get(`${roleName}/caleg/type/${calegTypeId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const showCaleg = async (accessToken, roleName, calegId) => {
    try {

        const response = await apiService.get(`${roleName}/caleg/show/${encodeURIComponent(calegId)}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const postCaleg = async (accessToken, roleName, calegTypeId, calegTypeName, name, email, namaPartai, noUrut, dapilId) => {
    try {

        let body;
        if (calegTypeName === 'DPRD KABUPATEN') {
            body = {
                'caleg_type_id': encodeURIComponent(calegTypeId),
                'name': name,
                'email': email,
                'nama_partai': namaPartai,
                'no_urut': noUrut,
                'dapil_id': encodeURIComponent(dapilId)
            }
        } else {
            body = {
                'caleg_type_id': encodeURIComponent(calegTypeId),
                'name': name,
                'email': email,
                'nama_partai': namaPartai,
                'no_urut': noUrut,
            }
        }

        const response = await apiService.post(`${roleName}/caleg/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const deleteCaleg = async (accessToken, roleName, calegId) => {
    try {

        const response = await apiService.post(`${roleName}/caleg/delete/${encodeURIComponent(calegId)}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        return response
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};