import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>

  // <Router>
  //   <Routes>
  //     <Route path='/' element={<App/>}></Route>
  //     <Route path='/login' element={<Login/>}></Route>
  //     <Route path='/404' element={<NotFoundPage/>}></Route>
  //     <Route path='/dashboard' element={<Dashboard/>}></Route>
  //     <Route path='/dashboard/caleg' element={<Caleg/>}></Route>
  //     <Route path='/dashboard/caleg/:type_id' element={<CalegList/>}></Route>
  //     <Route path='/dashboard/caleg/detail/:caleg_id' element={<CalegDetail/>}></Route>
  //     <Route path='/dashboard/dpt' element={<Dpt/>}></Route>
  //     <Route path='/dashboard/admin' element={<Admin/>}></Route>
  //     <Route path='/dashboard/relawan' element={<Relawan/>}></Route>
  //     <Route path='/dashboard/relawan/detail/:id' element={<RelawanDetail/>}></Route>
  //     <Route path='/dashboard/voter/list' element={<Voter/>}></Route>
  //     <Route path='/dashboard/presensi/voter/' element={<PresensiVoter/>}></Route>
  //     <Route path='/dashboard/form-c1' element={<FormC1/>}></Route>
  //     <Route path='/dashboard/pengaturan' element={<Setting/>}></Route>
  //   </Routes>
  // </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
