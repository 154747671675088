import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useEffect, useState } from "react"
import { getProfile } from "../../../services/auth_service"
import TemplateText from "../../../components/template_text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons"

const Setting = () => {

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [user, setUser] = useState(null)


    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        getProfile(accessToken).then((response) => {
            if (response && response.status === 200) {
                setUser(response.data.data)
            } else {
                setUser(null)
            }
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Pengaturan Akun</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full">
                    <div class="w-full h-14 bg-blue-500 md:rounded-tl-full flex items-center justify-between px-10 text-white">
                        <div></div>
                        <p class="text-xl font-semibold text-white text-center">Pengaturan Akun</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div className="w-full flex items-start p-3">
                    {/* <div className="md:w-[20%] aspect-square flex items-center justify-center ">
                        <FontAwesomeIcon icon={faUserCircle} className="text-8xl"></FontAwesomeIcon>
                    </div> */}
                    <div className="md:w-1/2 space-y-3">
                        {/* <p className="font-semibold">Detail Relawan</p> */}
                        <TemplateText name="No Kartu Keluarga" value={user?.nkk}></TemplateText>
                        <TemplateText name="NIK" value={user?.nik}></TemplateText>
                        <TemplateText name="Nama Lengkap" value={user?.nama}></TemplateText>
                        <TemplateText name="Tempat Lahir" value={user?.tempat_lahir}></TemplateText>
                        <TemplateText name="Tanggal Lahir" value={user?.tanggal_lahir}></TemplateText>
                        <TemplateText name="Usia" value={user?.usia}></TemplateText>
                        <TemplateText name="Status Perkawinan" value={user?.status_perkawinan}></TemplateText>
                        <TemplateText name="No TPS" value={user?.no_tps}></TemplateText>
                        <TemplateText name="Role" value={user?.role_name}></TemplateText>
                    </div>
                    <div className="md:w-1/2 space-y-3">
                        {/* <p className="font-semibold">Alamat Relawan</p> */}
                        <TemplateText name="Provinsi" value={user?.alamat?.provinsi}></TemplateText>
                        <TemplateText name="Kabupaten" value={user?.alamat?.kabupaten}></TemplateText>
                        <TemplateText name="Kecamatan" value={user?.alamat?.kecamatan}></TemplateText>
                        <TemplateText name="Kecamatan" value={user?.alamat?.kelurahan}></TemplateText>
                        <TemplateText name="rt" value={user?.alamat?.rt}></TemplateText>
                        <TemplateText name="rw" value={user?.alamat?.rw}></TemplateText>
                        <TemplateText name="detail" value={user?.alamat?.detail}></TemplateText>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default Setting