import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"

const FormC1 = () => {
    return (
        <>
            <Helmet>
                <title>Form C1</title>
            </Helmet>
            <LayoutSuperAdmin>
                <div class="sticky top-0 h-14 bg-yellow-400 w-full">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-10 text-white">
                        <div></div>
                        <p class="text-xl font-semibold text-white text-center">Form C1 SPARTA 24</p>
                        <button id="" type="button" class="font-bold text-xl hover:scale-110 transition all">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default FormC1 