import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginService } from "../services/auth_service";
import { Select, Tab, Ripple, Input, initTE } from "tw-elements"
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icon from "../images/tangan_bendera.png"
import { Helmet } from "react-helmet";
import Logo from "../images/sparta.png"

const Login = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        if (email.trim() !== '' && password.trim() !== '') {
            setIsLoading(true)

            loginService(email, password).then((response) => {
                if (response && response.status === 200) {
                    localStorage.setItem('role_name', response.data.role_name);
                    localStorage.setItem('access_token', response.data.access_token);
                    setIsLoading(false)
                    navigate("/dashboard")
                } else {
                    alert('Email / Nik / Password Salah..!');
                    setIsLoading(false)
                    console.log('gagal');
                }
            });
        } else {
            alert('Formulir input tidak boleh kosong');
        }
    };

    const handleShowPassword = () => {
        if (showPassword === false) {
            setShowPassword(true)
        } else {
            setShowPassword(false)
        }
    }

    useEffect(() => {
        initTE({ Select, Tab, Ripple, Input });
    }, [])

    return (
        <>
            <Helmet>
                <title>Login - SPARTA 24</title>
            </Helmet>
            <section className="h-screen w-full px-5 md:px-10 relative">
                {/* <a href="/" className="hidden md:block absolute top-10 left-16 px-10 py-2 bg-red-400 hover:bg-red-500 transition-colors rounded-md text-white">
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                    <span className="ml-3 font-semibold">Kembali</span>
                </a> */}

                <div
                    className="flex flex-col lg:flex-row items-center justify-center w-full h-full space-y-5 md:space-y-0">
                    {/* <!-- Left column container with background--> */}
                    <div className="w-full lg:w-1/2 hidden md:block">
                        <img src={icon} alt="Icon" className="w-[50%] mx-auto" />
                        <p className="text-center font-bold text-3xl md:text-4xl">SPARTA 24</p>
                    </div>

                    <div className="w-full lg:w-1/2">
                        <img src={Logo} alt="Logo Sparta" className="w-[50%] md:w-[30%] mx-auto"></img>
                        <p className="font-bold text-2xl text-center md:text-3xl mb-10 mt-3">Login</p>
                        <form className="w-full md:w-[70%] mx-auto space-y-5" onSubmit={handleLogin}>
                            <div class="relative mb-3" data-te-input-wrapper-init>
                                <input
                                    type="mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-yellow-400 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-yellow-400 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="masukan nik / email" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-pri peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-yellow-400"
                                >
                                    NIK / Email
                                </label>
                            </div>

                            <div class="relative mb-3" data-te-input-wrapper-init>
                                <input
                                    type={showPassword === false ? 'password' : 'text'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput1"
                                    placeholder="masukan password" />
                                <label
                                    for="exampleFormControlInput1"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                >
                                    Password
                                </label>
                                <button type="button" className={`absolute right-5 bottom-1  ${showPassword ? ' text-black' : 'text-custom-gold'}`} onClick={handleShowPassword}>
                                    <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                                </button>
                            </div>
                            <div className="pt-10">
                                <button type="submit" className="bg-yellow-400 hover:bg-yellow-500 transition-colors flex items-center justify-center py-2 font-semibold rounded-md w-full" >
                                    {isLoading === true ? <svg className="animate-spin h-5 w-5 mr-3 bg-black" viewBox="0 0 24 24">

                                    </svg> : ''}
                                    Masuk
                                </button>
                            </div>
                        </form>
                        <a href="/" >
                            <div className="text-center mt-5 text-yellow-600 font-semibold hover:text-yellow-500">
                                Kembali ke halaman depan
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login