import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { useEffect, useState } from "react";
import {
    Select,
    Dropdown,
    Modal,
    Input,
    Ripple,
    initTE,
} from "tw-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faFileExport, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { deleteRelawan, getRelawan, importRelawan, postRelawan, searchRelawan } from "../../../services/relawan_service";
import { getDistrict, getTps, getVillage } from "../../../services/location_service";
import TemplateText from "../../../components/template_text";

const Relawan = () => {
    const navigate = useNavigate()

    let [number, setNumber] = useState(1)

    const [district, setDistrict] = useState([]);
    const [village, setVillage] = useState([]);
    const [tps, setTps] = useState([]);

    const [regencyId, setRegencyId] = useState(3205);
    const [districtId, setDistrictId] = useState(0);
    const [villageId, setVillageId] = useState(0);
    const [tpsId, setTpsId] = useState(0);

    const [relawan, setRelawan] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalRelawan, setTotalRelawan] = useState(0)

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [connection, setConnection] = useState(true)
    const [isLoadingPost, setIsLoadingPost] = useState(false)

    const [nik, setNik] = useState('')
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const [openModalImport, setOpenModalImport] = useState(false)
    const [openModalManualAdd, setOpenModalManualAdd] = useState(false)
    const [openModalRekapImport, setOpenModalRekapImport] = useState(false)

    const [createCount, setCreateCount] = useState(0)
    const [updateCount, setUpdateCount] = useState(0)
    const [failedCount, setFailedCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [message, setMessage] = useState('')
    const [showPopUpManualAdd, setShowPopUpManualAdd] = useState(false)

    const handleSearchChange = (e) => {
        const newKeyword = e.target.value;
        setKeyword(newKeyword)
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsLoading(true)
        searchRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1, keyword).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleDeleteRelawan = (relawanId) => {
        deleteRelawan(accessToken, roleName, relawanId)

        getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleRegency = async (e) => {
        const regencyId = e.target.value;
        setRegencyId(regencyId)
        setDistrictId(0)
        getDistrict(regencyId).then((response) => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            } else {
                setDistrict([])
            }
        })
        setVillage([])
        setVillageId(0)
        setTps([])
        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)
        setCurrentPage(1)
        getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleDistrict = async (e) => {
        const districtId = e.target.value;
        setDistrictId(districtId)
        if (districtId === 0) {
            setTps([])
        } else {
            getVillage(districtId).then((response) => {
                if (response && response.status === 200) {
                    setVillage(response.data.data)
                } else {
                    setVillage([])
                }
            })
        }

        setVillageId(0)
        setTps([])
        setTpsId(0)

        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)

        getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });

    }

    const handleVillage = async (e) => {
        const villageId = e.target.value;
        setVillageId(villageId)
        console.log(villageId);

        if (villageId === 0) {
            setTps([])
        } else {
            getTps(villageId).then((response) => {
                setTpsId(0)
                if (response && response.status === 200) {
                    setTps(response.data)
                } else {
                    setTps([])
                }
            });
        }

        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)

        getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleTps = async (e) => {
        const tpsId = e.target.value;
        setTpsId(tpsId)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)
        getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, 1).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)

                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newCurrentPage = currentPage - 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setIsLoading(false)
                    setRelawan(response.data.data);
                    setTotalRelawan(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setRelawan([])
                }
            });
        }
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            const newCurrentPage = currentPage + 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setIsLoading(false)
                    setRelawan(response.data.data);
                    setTotalRelawan(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setRelawan([])
                }
            });
        }
    }

    const handleChangeFile = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setError('File harus berformat .xlsx');
                setFile(null);
            } else {
                setFile(selectedFile);
                setError('');
            }
        }
    };


    const handleSubmitImport = (e) => {
        e.preventDefault()
        setIsLoadingPost(true)
        importRelawan(accessToken, roleName, file).then((response) => {
            if (response && response.status === 200) {
                setCreateCount(response.data.create_count)
                setUpdateCount(response.data.update_count)
                setFailedCount(response.data.failed_count)
                setTotalCount(response.data.total_count)
                setOpenModalRekapImport(true)
                setIsLoading(false)
                setOpenModalImport(false)
                setIsLoadingPost(false)

                getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
                    setIsLoading(true)
                    setConnection(true)
                    if (response && response.status === 200) {
                        setRefresh(false)
                        setIsLoading(false)
                        setRelawan(response.data.data);
                        setTotalRelawan(response.data.meta.total);
                        setNumber(response.data.meta.from)

                        setCurrentPage(response.data.meta.current_page)
                        setLastPage(response.data.meta.last_page)
                    } else {
                        setIsLoading(false)
                        setRelawan([])
                    }
                });

            } else {
                setCreateCount(0)
                setUpdateCount(0)
                setFailedCount(0)
                setTotalCount(0)
                setIsLoading(false)
                setIsLoadingPost(false)
                setOpenModalImport(false)
            }
        });

    };

    const handleChangeNik = (e) => {
        const newNik = e.target.value;
        setNik(newNik)
    };

    const handleSubmitManual = (e) => {
        e.preventDefault()
        setIsLoadingPost(true)
        postRelawan(accessToken, roleName, nik).then((response) => {
            setIsLoading(true)
            setRefresh(true)
            setConnection(true)
            if (response && response.status === 200) {
                setShowPopUpManualAdd(true)
                setOpenModalManualAdd(false)
                setIsLoadingPost(false)
                setIsLoading(false)
                setRefresh(false)
                setConnection(false)

                setMessage(response.data.message)

                getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
                    setIsLoading(true)
                    setConnection(true)
                    if (response && response.status === 200) {
                        setRefresh(false)
                        setIsLoading(false)
                        setRelawan(response.data.data);
                        setTotalRelawan(response.data.meta.total);
                        setNumber(response.data.meta.from)

                        setCurrentPage(response.data.meta.current_page)
                        setLastPage(response.data.meta.last_page)
                    } else {
                        setIsLoading(false)
                        setRelawan([])
                    }
                });
            } else if (response && response.status === 400) {
                setShowPopUpManualAdd(true)
                setMessage(response.data.message)
            } else {
                setShowPopUpManualAdd(true)
                setMessage(error)
                setOpenModalManualAdd(false)
                setIsLoadingPost(false)
                setIsLoading(false)
                setRefresh(false)
                setConnection(false)
            }
        });
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken == null) {
            navigate('/login')
        } else {
            getRelawan(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setRelawan(response.data.data);
                    setTotalRelawan(response.data.meta.total);
                    setNumber(response.data.meta.from)

                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setRelawan([])
                }
            });

            getDistrict(regencyId).then((response) => {

                if (response && response.status === 200) {
                    setDistrict(response.data.data)
                } else {
                    setDistrict([])
                }

            })
        }
        initTE({ Select, Modal, Input, Dropdown, Ripple })
    }, [currentPage, regencyId, districtId, villageId, tpsId, navigate]);

    return (
        <>
            <Helmet>
                <title>Dashboard - Sparta 24</title>
            </Helmet>
            <LayoutSuperAdmin>
                {/* Desktop Header */}
                <div class="sticky top-0 w-full h-14 bg-yellow-400 z-30 hidden md:block">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-5 text-white">
                        <div className="flex items-center space-x-3">
                            <form onSubmit={handleSearch}>
                                <div className="flex items-center text-sm pl-3">
                                    <input
                                        type="search"
                                        value={keyword}
                                        onChange={handleSearchChange}
                                        className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                        placeholder="telusuri"
                                    ></input>
                                    <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>

                            <div className="flex items-center space-x-3 font-semibold mb-5 md:mb-0">
                                {refresh === true ?
                                    <div className="flex items-center justify-center w-10 h-10">
                                        <div
                                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div> :
                                    <p className="font-semibold text-2xl">{totalRelawan.toLocaleString('id-ID')}</p>
                                }
                                <p>Relawan</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button id="" type="button" class="font-semibold text-sm space-x-3 bg-green-400 px-5 py-2 rounded-md text-black hover:bg-green-500 transition-colors">
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                                <span>Export Data</span>
                            </button>
                            <div class="relative" data-te-dropdown-ref>
                                <button
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded="false"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    class="font-semibold text-sm space-x-3 bg-yellow-400 px-5 py-2 rounded-md text-black hover:bg-yellow-500 transition-colors">
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                    <span>Tambat Data</span>
                                </button>
                                <ul
                                    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                    aria-labelledby="dropdownMenuButton1"
                                    data-te-dropdown-menu-ref>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => setOpenModalImport(true)}
                                            class="block text-center  w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Import Data
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => setOpenModalManualAdd(true)}
                                            class="block text-center w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Manual
                                        </button>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            class="block text-center  w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                            data-te-dropdown-item-ref
                                        >
                                            Scan KTP
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Header */}
                <div className="w-full sticky top-0 bg-blue-500 py-3 px-3 space-y-3 md:hidden z-40">
                    <div className="flex items-center space-x-3">
                        <form onSubmit={handleSearch}>
                            <div className="flex items-center text-sm ">
                                <input
                                    type="search"
                                    value={keyword}
                                    onChange={handleSearchChange}
                                    className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                    placeholder="telusuri"
                                ></input>
                                <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </button>
                            </div>
                        </form>
                        <button type="button" className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                            <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                        </button>
                        {/* <button type="button" className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </button> */}
                        <div class="relative" data-te-dropdown-ref>
                            <button
                                type="button"
                                id="dropdownMenuButton1"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                class="font-semibold text-sm space-x-3 bg-yellow-400 px-5 py-2 rounded-md text-black hover:bg-yellow-500 transition-colors">
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </button>
                            <ul
                                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton1"
                                data-te-dropdown-menu-ref>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => setOpenModalImport(true)}
                                        // data-te-toggle="modal"
                                        // data-te-target="#modalImportRelawan"
                                        // data-te-ripple-init
                                        // data-te-ripple-color="light"
                                        class="block text-center  w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        data-te-dropdown-item-ref
                                    >
                                        Import Data
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => setOpenModalManualAdd(true)}
                                        class="block text-center w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        data-te-dropdown-item-ref
                                    >
                                        Manual
                                    </button>
                                </li>
                                <li>
                                    <a
                                        href="/"
                                        class="block text-center  w-full whitespace-nowrap bg-transparent px-8 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        data-te-dropdown-item-ref
                                    >
                                        Scan KTP
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-center space-x-3 font-semibold md:mb-0 text-white">
                        {refresh === true ?
                            <div className="flex items-center justify-center w-10 h-10">
                                <div
                                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status">
                                    <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                        Loading...
                                    </span>
                                </div>
                            </div> :
                            <p className="font-semibold text-2xl">{totalRelawan.toLocaleString('id-ID')}</p>
                        }
                        <p>Relawan</p>
                    </div>
                </div>
                {/* End Mobile Header */}

                {/* Filter Desktop Version */}
                <div className="w-full hidden md:flex justify-between items-center px-5 py-3">
                    <div className="flex items-center space-x-3 text-sx">
                        <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                            <option value="3205">KABUPATEN GARUT</option>
                            <option value="3206">KABUPATEN TASIKMALAYA</option>
                            <option value="3278">KOTA TASIKMALAYA</option>
                        </select>
                        <select value={districtId} data-te-select-init data-te-select-filter="true" onChange={handleDistrict}>
                            <option value="" className="text-sm">Kecamatan</option>
                            {district?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={villageId} data-te-select-init data-te-select-filter="true" onChange={handleVillage}>
                            <option value="" className="text-sm">Kelurahan</option>
                            {village?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={tpsId} data-te-select-init data-te-select-filter="true" onChange={handleTps}>
                            <option value="0" className="text-sm">No TPS</option>
                            {tps?.map((item) => (
                                <option value={item.tps_number} >{item.tps_number}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* End Filter Desktop Version */}

                <div className="w-full px-3 md:px-5 overflow-x-auto scrollbar-thin scrollbar-track-black scrollbar-thumb-blue-700 scrollbar-track-rounded-full scrollbar-thumb-rounded-full relative ">
                    {/* Desktop Tabel */}
                    <table className="min-w-max w-full hidden md:block">
                        <thead className="">
                            <tr className="text-sm w-full">
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">Aksi</th>
                                <th className="w-16 py-2 aspect-square border-2 ">No</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">No Kartu Keluarga</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Nik</th>
                                <th className="w-72 py-2 aspect-square border-2 px-2 text-start overflow-auto">Nama</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kota / Kabupaten</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kecamatan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Desa / Kelurahan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">TPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading === false ?
                                relawan.map((dataRelawan) => (
                                    <tr className={`text-sm ${number % 2 === 1 ? 'bg-white hover:bg-gray-400' : 'bg-gray-200 hover:bg-gray-400'}`}>
                                        <td className="text-center px-3 py-2">
                                            <div class="relative" data-te-dropdown-ref>
                                                <button
                                                    className="confirm-delete text-red-500"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-te-dropdown-toggle-ref
                                                    aria-expanded="false"
                                                    data-te-ripple-init
                                                    data-te-ripple-color="light">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                <ul
                                                    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-yellow-50 bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                                    aria-labelledby="dropdownMenuButton1"
                                                    data-te-dropdown-menu-ref>
                                                    <div className="px-10 space-y-3 py-3 ">
                                                        <p className="text-center">Apakah Anda yakin <br />akan menghapus Relawan ini..?</p>
                                                        <div className="flex items-center justify-evenly space-x-3 font-semibold">
                                                            <button
                                                                type="button"
                                                                className="h-8 w-24 bg-green-400 hover:bg-green-500 rounded-md"
                                                            >
                                                                Tidak

                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleDeleteRelawan(dataRelawan.id)}
                                                                className="h-8 w-24 bg-red-400 hover:bg-red-500 rounded-md"
                                                            >
                                                                Ya
                                                            </button>
                                                        </div>
                                                    </div>

                                                </ul>
                                            </div>
                                        </td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer text-center">{number++}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer bg-gray px-3 w-fit">{dataRelawan.nkk}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.nik}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.nama}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kabupaten}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kecamatan}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kelurahan}</td>
                                        <td onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-3 py-2 text-center">TPS {dataRelawan?.no_tps}</td>

                                    </tr>
                                )) : Array.from({ length: 20 }, (_, j) => (
                                    <tr key={j} className="animate-pulse w-full">
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <td key={i} className="px-3 py-2">
                                                <div className="h-5 bg-slate-200 rounded"></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {/* End Desktop Tabel */}

                    {/* Mobile Version Card */}
                    <div className="space-y-3 pt-3 md:hidden">
                        {isLoading === false ?
                            relawan.map((dataRelawan) => (
                                <div onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="w-full h-16 bg-yellow-50 drop-shadow-md flex items-center justify-between text-sm rounded-md px-3">
                                    <div className="flex space-x-3">
                                        {dataRelawan.image == null ?
                                            <div className="h-10 aspect-square bg-yellow-400 rounded-full"></div> :
                                            <img src={dataRelawan.image} alt="Relawan Sparta" className="w-10 aspect-square rounded-md object-cover object-center"></img>}
                                        <div>
                                            <p className="font-semibold ">{dataRelawan.nama}</p>
                                            <p>{dataRelawan.nik}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center">
                                        <p>TPS</p>
                                        <p>{dataRelawan.no_tps}</p>
                                    </div>
                                </div>
                            )) : ''}
                    </div>
                    {/* End Card Mobile Version */}

                    <div className="w-full bg-white h-3"></div>
                    <div className="w-full flex items-center justify-end space-x-3 py-5 bg-gray-200 px-5">
                        <button type="button"
                            onClick={handlePreviousPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                            <span className="ml-3">Kembali</span>
                        </button>
                        <p className="text-xl font-bold ">{currentPage}</p>
                        <button type="button"
                            onClick={handleNextPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <span className="mr-3">Selanjutnya</span>
                            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
                        </button>
                    </div>
                </div>
            </LayoutSuperAdmin>

            {/* Modal Import Relawan */}
            <div className={`absolute top-0 h-screen w-full flex items-center justify-center ${openModalImport === false ? 'z-0' : 'z-[15000]'}`}>
                <form onSubmit={handleSubmitImport} className={`${openModalImport === false ? 'w-0 h-0 rounded-full' : 'w-full h-full'} bg-black/50 flex items-center justify-center transition-all duration-700 overflow-hidden`}>
                    <div className="h-fit w-[90%] md:[75%] lg:w-[500px] bg-white rounded-xl">
                        {/* Header */}
                        <div className="py-5 drop-shadow-md text-center border-b-2">
                            <p className="font-semibold text-xl">Import Relawan (.xlsx)</p>
                        </div>
                        {/* Body */}
                        <div className="p-5">
                            <label className="font-semibold">File Excel (.xlsx)</label>
                            <input type="file" onChange={handleChangeFile} className="px-3 py-2 border-2 rounded-md outline-none focus:border-yellow-400 w-full" />
                            {error && <div style={{ color: 'red' }}>{error}</div>}
                        </div>

                        {/* Footer */}
                        <div className="py-3 drop-shadow-md text-center border-t-2 flex items-center px-5 w-full justify-end space-x-3">
                            <button type="button" onClick={() => setOpenModalImport(false)} className="flex items-center justify-center w-32 py-2 bg-red-400 hover:bg-red-500 transition-all duration-300 rounded-md font-semibold">
                                Kembali
                            </button>

                            <button type="submit" className="flex items-center justify-center w-32 py-2 bg-yellow-400 hover:bg-yellow-500 transition-all duration-300 rounded-md font-semibold">
                                {isLoadingPost === true ? <svg className="animate-spin h-5 w-5 mr-3 bg-black" viewBox="0 0 24 24">

                                </svg> : ''}
                                Simpan
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            {/* Modal Manual Relawan */}
            <div className={`absolute top-0 h-screen w-full flex items-center justify-center ${openModalManualAdd === false ? 'z-0' : 'z-[15000]'}`}>
                <form onSubmit={handleSubmitManual} className={`${openModalManualAdd === false ? 'w-0 h-0 rounded-full' : 'w-full h-full'} bg-black/50 flex items-center justify-center transition-all duration-700 overflow-hidden`}>
                    <div className="h-fit w-[90%] md:w-[70%] lg:w-[500px] bg-white rounded-xl">,
                        {/* Header */}
                        <div className="py-5 drop-shadow-md text-center border-b-2">
                            <p className="font-semibold text-xl">Tambah Data Relawan By NIK</p>
                        </div>
                        {/* Body */}
                        <div className="p-5">
                            <label className="font-semibold">NIK)</label>
                            <input type="text" value={nik} onChange={handleChangeNik} className="px-3 py-2 border-2 rounded-md outline-none focus:border-yellow-400 w-full" />
                        </div>

                        {/* Footer */}
                        <div className="py-3 drop-shadow-md text-center border-t-2 flex items-center px-5 w-full justify-end space-x-3">
                            <button type="button" onClick={() => setOpenModalManualAdd(false)} className="flex items-center justify-center w-32 py-2 bg-red-400 hover:bg-red-500 transition-all duration-300 rounded-md font-semibold">
                                Kembali
                            </button>

                            <button type="submit" className="flex items-center justify-center w-32 py-2 bg-yellow-400 hover:bg-yellow-500 transition-all duration-300 rounded-md font-semibold">
                                {isLoadingPost === true ? <svg className="animate-spin h-5 w-5 mr-3 bg-black" viewBox="0 0 24 24">

                                </svg> : ''}
                                Simpan
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            {/* Modal PopUp  */}
            <div className={`absolute top-0 h-screen w-full flex items-center justify-center ${openModalRekapImport === true ? 'z-[1503]' : 'z-10'}`}>
                <div className={` ${openModalRekapImport === true ? 'w-full h-full' : 'w-0 h-0'} bg-black/50 flex items-center justify-center transition-all duration-700 overflow-hidden`}>
                    <div className="h-fit w-[35%] bg-white rounded-xl">
                        {/* Header */}
                        <div className="py-5 drop-shadow-md text-center border-b-2">
                            <p className="font-semibold text-xl">Rekap Import Relawan</p>
                        </div>
                        {/* Body */}
                        <div className="p-5 space-y-3">
                            <TemplateText name="Create Count" value={createCount}></TemplateText>
                            <TemplateText name="Update Count" value={updateCount}></TemplateText>
                            <TemplateText name="Failed Count" value={failedCount}></TemplateText>
                            <TemplateText name="Total Count" value={totalCount}></TemplateText>
                        </div>

                        {/* Footer */}
                        <div className="py-3 drop-shadow-md text-center border-t-2 flex items-center px-5 w-full justify-end space-x-3">
                            <button type="button" onClick={() => setOpenModalRekapImport(false)} className="flex items-center justify-center w-32 py-2 bg-yellow-400 hover:bg-yellow-500 transition-all duration-300 rounded-md font-semibold">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal PopUp  Manual Add*/}
            <div className={`absolute top-0 h-screen w-full flex items-center justify-center ${showPopUpManualAdd === true ? 'z-[1504]' : 'z-20'}`}>
                <div className={` ${showPopUpManualAdd === true ? 'w-full h-full' : 'w-0 h-0'} bg-black/50 flex items-center justify-center transition-all duration-700 overflow-hidden`}>
                    <div className="h-fit w-[35%] bg-white rounded-xl">
                        {/* Header */}
                        <div className="py-5 drop-shadow-md text-center border-b-2">
                            <p className="font-semibold text-xl">Report Tambah Data Relawan</p>
                        </div>
                        {/* Body */}
                        <div className="p-5 space-y-3">
                            <p className="text-center font-semibold text-2xl">{message}</p>
                        </div>

                        {/* Footer */}
                        <div className="py-3 drop-shadow-md text-center border-t-2 flex items-center px-5 w-full justify-end space-x-3">
                            <button type="button" onClick={() => setShowPopUpManualAdd(false)} className="flex items-center justify-center w-32 py-2 bg-yellow-400 hover:bg-yellow-500 transition-all duration-300 rounded-md font-semibold">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Relawan