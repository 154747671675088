import axios from "axios";

axios.interceptors.request.use(
    (config) => {
      // Menambahkan header 'Origin' jika diperlukan
      config.headers['Origin'] = 'https://sparta24.id/'; // Ganti dengan origin yang sesuai
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default axios;