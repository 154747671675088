import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faFileExport,
    faFilter,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { deleteAdmin, getAdmin, postAdmin } from "../../../services/admin_service"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Select, Modal, Dropdown, Input, Ripple, Popconfirm, initTE } from "tw-elements"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getDistrict, getVillage } from "../../../services/location_service";

const Admin = () => {
    const navigate = useNavigate()

    let [number, setNumber] = useState(1)
    const [admin, setAdmin] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalAdmin, setTotalAdmin] = useState(0)

    const [district, setDistrict] = useState([]);
    const [village, setVillage] = useState([]);

    const [regencyId, setRegencyId] = useState(3205);
    const [districtId, setDistrictId] = useState(0);
    const [villageId, setVillageId] = useState(0);
    const [tpsId, setTpsId] = useState(0);

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [connection, setConnection] = useState(true)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const handleSearchChange = (e) => {
        const newKeyword = e.target.value;
        setKeyword(newKeyword)
    };

    const handleChangeName = (e) => {
        const newName = e.target.value;
        setName(newName)
    };

    const handleChangeEmail = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postAdmin(accessToken, roleName, name, email, regencyId, districtId, villageId).then((response) => {
        });

        getAdmin(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setAdmin(response.data.data);
                setTotalAdmin(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setAdmin([])
            }
        });
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setIsLoading(true)
    }
    const handleExportAdmin = () => {
        // exportAdmin(accessToken, regencyId, districtId, villageId, tpsId, currentPage)
    }

    const handleRegency = async (e) => {
        const regencyId = e.target.value;
        setRegencyId(regencyId)
        setDistrictId(0)
        getDistrict(regencyId).then((response) => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            } else {
                setDistrict([])
            }
        })
        setVillage([])
        setVillageId(0)
    }

    const handleDistrict = async (e) => {
        const districtId = e.target.value;
        setDistrictId(districtId)
        getVillage(districtId).then((response) => {
            if (response.status === 200) {
                setVillage(response.data.data)
            } else {
                setVillage([])
            }
        })

        setVillageId(0)
    }

    const handleVillage = async (e) => {
        const villageId = e.target.value;
        setVillageId(villageId)
    }


    const handleDeleteAdmin = (AdminId) => {
        deleteAdmin(accessToken, roleName, AdminId)
        getAdmin(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setAdmin(response.data.data);
                setTotalAdmin(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setAdmin([])
            }
        });
    }

    const handleRedirectToDetail = (relawanId) => {
        navigate(`/dashboard/admin/detail/${relawanId}`)
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newCurrentPage = currentPage - 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getAdmin(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response.status === 200) {
                    setIsLoading(false)
                    setAdmin(response.data.data);
                    setTotalAdmin(response.data.meta.total);
                    setNumber(response.data.meta.from)
                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setAdmin([])
                }
            });
        }
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            const newCurrentPage = currentPage + 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getAdmin(accessToken, roleName, regencyId, districtId, villageId, tpsId, newCurrentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response.status === 200) {
                    setIsLoading(false)
                    setAdmin(response.data.data);
                    setTotalAdmin(response.data.meta.total);
                    setNumber(response.data.meta.from)
                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setAdmin([])
                }
            });
        }
    }


    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)

        if (accessToken === "Bearer null" || accessToken === null) {
            navigate('/login')
        } else {
            getAdmin(accessToken, roleName, regencyId, districtId, villageId, tpsId, currentPage).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setAdmin(response.data.data);
                    setTotalAdmin(response.data.meta.total);
                    setNumber(response.data.meta.from)
                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setAdmin([])
                }
            });

            getDistrict(regencyId).then((response) => {

                if (response && response.status === 200) {
                    setDistrict(response.data.data)
                } else {
                    setDistrict([])
                }

            })

            initTE({ Select, Modal, Input, Dropdown, Ripple, Popconfirm, })
        }
    }, [currentPage, regencyId, districtId, villageId, tpsId, navigate]);
    return (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <LayoutSuperAdmin>
                {/* Desktop Header */}
                <div class="sticky top-0 w-full h-14 bg-yellow-400 z-30 hidden md:block">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-5 text-white">
                        <div className="flex items-center space-x-3">
                            <form onSubmit={handleSearch}>
                                <div className="flex items-center text-sm pl-3">
                                    <input
                                        type="search"
                                        value={keyword}
                                        onChange={handleSearchChange}
                                        className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                        placeholder="telusuri"
                                    ></input>
                                    <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>

                            <div className="flex items-center space-x-3 font-semibold mb-5 md:mb-0">
                                {refresh === true ?
                                    <div className="flex items-center justify-center w-10 h-10">
                                        <div
                                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div> :
                                    <p className="font-semibold text-2xl">{totalAdmin.toLocaleString('id-ID')}</p>
                                }
                                <p>Admin</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button id="" type="button" class="font-semibold text-sm space-x-3 bg-green-400 px-5 py-2 rounded-md text-black hover:bg-green-500 transition-colors">
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                                <span>Export Data</span>
                            </button>
                            <button
                                type="button"
                                data-te-toggle="modal"
                                data-te-target="#modalAddAmin"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                class="font-semibold text-sm space-x-3 bg-yellow-400 px-5 py-2 rounded-md text-black hover:bg-yellow-500 transition-colors">
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                <span>Tambat Data</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Emd Desktop Header */}

                {/* Mobile Header */}
                <div className="w-full sticky top-0 bg-blue-500 py-3 px-3 space-y-3 md:hidden z-40">
                    <div className="flex items-center space-x-3">
                        <form onSubmit={handleSearch}>
                            <div className="flex items-center text-sm ">
                                <input
                                    type="search"
                                    value={keyword}
                                    onChange={handleSearchChange}
                                    className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                    placeholder="telusuri"
                                ></input>
                                <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </button>
                            </div>
                        </form>
                        <button
                            type="button"
                            data-te-toggle="modal"
                            data-te-target="#mobileFilter"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                            <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                        </button>

                        <div class="relative" data-te-dropdown-ref>
                            <button
                                type="button"
                                id="dropdownMenuButton2"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </button>
                            <ul
                                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton2"
                                data-te-dropdown-menu-ref>
                                <li>
                                    <a
                                        class="block w-full whitespace-nowrap bg-transparent py-2 px-10 text-sm font-semibold text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        href="/"
                                        data-te-dropdown-item-ref
                                    >
                                        Import
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="block w-full whitespace-nowrap bg-transparent px-10 py-2 text-sm font-semibold text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                        href="/"
                                        data-te-dropdown-item-ref
                                    >
                                        Manual
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-center space-x-3 font-semibold md:mb-0 text-white">
                        {refresh === true ?
                            <div className="flex items-center justify-center w-10 h-10">
                                <div
                                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status">
                                    <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                        Loading...
                                    </span>
                                </div>
                            </div> :
                            <p className="font-semibold text-2xl">{totalAdmin.toLocaleString('id-ID')}</p>
                        }
                        <p>Admin</p>
                    </div>
                </div>
                {/* End Mobile Header */}

                {/* Modal Add Admin */}
                <div
                    data-te-modal-init="true"
                    class="fixed left-0 top-0 z-[1055]  hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black/50"
                    id="modalAddAmin"
                    data-te-open="true"
                    tabindex="-1"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-modal="true"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div
                        data-te-modal-dialog-ref="true"
                        class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] transform-none">
                        <div
                            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                            <form onSubmit={handleSubmit}>
                                <div
                                    class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                    {/* <!--Modal title--> */}
                                    <h5
                                        class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                        id="exampleModalCenterTitle">
                                        Tambah Admin
                                    </h5>
                                    {/* <!--Close button--> */}
                                    <button
                                        type="button"
                                        class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        data-te-modal-dismiss
                                        aria-label="Close">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="h-6 w-6">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative flex-auto p-3 space-y-3 w-full" data-te-modal-body-ref>
                                    <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                                        <option value="3205">KABUPATEN GARUT</option>
                                        <option value="3206">KABUPATEN TASIKMALAYA</option>
                                        <option value="3278">KOTA TASIKMALAYA</option>
                                    </select>
                                    <select value={districtId} data-te-select-init data-te-select-filter="true" onChange={handleDistrict}>
                                        <option value="" className="text-sm">Kecamatan</option>
                                        {district?.map((item) => (
                                            <option value={item.id} >{item.name}</option>
                                        ))}
                                    </select>

                                    <select value={villageId} data-te-select-init data-te-select-filter="true" onChange={handleVillage}>
                                        <option value="" className="text-sm">Kelurahan</option>
                                        {village?.map((item) => (
                                            <option value={item.id} >{item.name}</option>
                                        ))}
                                    </select>

                                    <div class="relative mb-3" data-te-input-wrapper-init>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={handleChangeName}
                                            class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                            id="exampleFormControlInput1"
                                            placeholder="Example label" />
                                        <label
                                            for="exampleFormControlInput1"
                                            class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                        >
                                            Nama Lengkap
                                        </label>
                                    </div>


                                    <div class="relative mb-3" data-te-input-wrapper-init>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={handleChangeEmail}
                                            class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                            id="exampleFormControlInput1"
                                            placeholder="Example label" />
                                        <label
                                            for="exampleFormControlInput1"
                                            class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                        >
                                            Email
                                        </label>
                                    </div>
                                </div>

                                {/* <!--Modal footer--> */}
                                <div
                                    class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                    <button
                                        type="submit"
                                        class="bg-yellow-500 hover:bg-yellow-500 px-5 py-2 rounded-md font-semibold"
                                        data-te-ripple-init
                                        data-te-ripple-color="light">
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* End Modal Add Admin */}

                <div className="w-full p-5">
                    <table className="w-full min-w-max text-sm">
                        <thead>
                            <tr className="bg-gray-200">
                                <th scope="col" className="px-3 py-2">No</th>
                                <th scope="col" className="px-2 py-2 w-fit text-start">Profil</th>
                                <th scope="col" className="px-2 py-2 w-fit text-start">Nama</th>
                                <th scope="col" className="px-2 py-2 w-fit text-start">Email</th>
                                <th scope="col" className="px-3 py-2 text-center">Aksi</th>
                            </tr>
                        </thead>
                        <tbody className="mb-5">
                            {isLoading === false ?
                                admin.map((dataAdmin) => (
                                    <tr className={`pointer-events-auto text-sm ${number % 2 === 1 ? 'bg-white hover:bg-gray-400' : 'bg-gray-200 hover:bg-gray-400'}`} >
                                        <td onClick={() => handleRedirectToDetail(dataAdmin.id)} className="cursor-pointer text-center">{number++}</td>
                                        <td onClick={() => handleRedirectToDetail(dataAdmin.id)} className="cursor-pointer bg-gray px-3 w-fit py-2">
                                            <div className="w-16 aspect-square rounded-full bg-yellow-400 flex items-center justify-center">
                                                GH
                                            </div>
                                        </td>
                                        <td onClick={() => handleRedirectToDetail(dataAdmin.id)} className="cursor-pointer px-2 py-2 w-fit">{dataAdmin.name}</td>
                                        <td onClick={() => handleRedirectToDetail(dataAdmin.id)} className="cursor-pointer px-2 py-2 w-fit">{dataAdmin.email}</td>
                                        <td className="text-center px-3 py-2">
                                            <div class="relative" data-te-dropdown-ref>
                                                <button
                                                    className="confirm-delete text-red-500"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-te-dropdown-toggle-ref
                                                    aria-expanded="false"
                                                    data-te-ripple-init
                                                    data-te-ripple-color="light">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                <ul
                                                    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-yellow-50 bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                                                    aria-labelledby="dropdownMenuButton1"
                                                    data-te-dropdown-menu-ref>
                                                    <div className="px-10 space-y-3 py-3 ">
                                                        <p className="text-center">Apakah Anda yakin <br />akan menghapus Admin ini..?</p>
                                                        <div className="flex items-center justify-evenly space-x-3 font-semibold">
                                                            <button
                                                                type="button"
                                                                className="h-8 w-24 bg-green-400 hover:bg-green-500 rounded-md"
                                                            >
                                                                Tidak

                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={()=>handleDeleteAdmin(dataAdmin.id)}
                                                                className="h-8 w-24 bg-red-400 hover:bg-red-500 rounded-md"
                                                            >
                                                                Ya
                                                            </button>
                                                        </div>
                                                    </div>

                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                )) : Array.from({ length: 20 }, (_, j) => (
                                    <tr key={j} className="animate-pulse">
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <td key={i} className="px-3 py-2">
                                                <div className="h-5 bg-slate-200 rounded"></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            {connection === true ? '' : <div>
                                silahkan Periksa koneksi internet Anda..!
                            </div>}
                        </tbody>
                    </table>
                    <div className="w-full bg-white h-3"></div>
                    <div className=" w-full flex items-center justify-end space-x-3 py-3 bg-gray-200 px-5">
                        <button type="button"
                            onClick={handlePreviousPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                            <span className="ml-3">Kembali</span>
                        </button>
                        <p className="text-xl font-bold ">{currentPage}</p>
                        <button type="button"
                            onClick={handleNextPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <span className="mr-3">Selanjutnya</span>
                            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
                        </button>
                    </div>

                </div>
            </LayoutSuperAdmin >
        </>
    )
}

export default Admin