import { Helmet } from "react-helmet"
import LayoutSuperAdmin from "../../../components/layouts/layout_super_admin"
import { getCalegRelawan, searchCalegRelawan } from "../../../services/caleg_relawan_service"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getDistrict, getTps, getVillage } from "../../../services/location_service"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCircleChevronLeft, faFileExport, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"

const CalegRelawan = () => {
    const navigate = useNavigate()
    const { caleg_id } = useParams()
    const { type_id } = useParams()

    const [roleName, setRoleName] = useState('')
    const [accessToken, setAccesToken] = useState('')

    let [number, setNumber] = useState(1)

    const [relawan, setRelawan] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalRelawan, setTotalRelawan] = useState(0)

    const [district, setDistrict] = useState([]);
    const [village, setVillage] = useState([]);
    const [tps, setTps] = useState([]);

    const [regencyId, setRegencyId] = useState(3205);
    const [districtId, setDistrictId] = useState(0);
    const [villageId, setVillageId] = useState(0);
    const [tpsId, setTpsId] = useState(0);

    const [isLoading, setIsLoading] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [connection, setConnection] = useState(true)

    const [caleg, setCaleg] = useState(null)


    const handleRegency = async (e) => {
        const regencyId = e.target.value;
        setRegencyId(regencyId)
        setDistrictId(0)
        getDistrict(regencyId).then((response) => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            } else {
                setDistrict([])
            }
        })
        setVillage([])
        setVillageId(0)
        setTps([])
        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)
        setCurrentPage(1)
        getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleDistrict = async (e) => {
        const districtId = e.target.value;
        setDistrictId(districtId)
        if (districtId === 0) {
            setTps([])
        } else {
            getVillage(districtId).then((response) => {
                if (response && response.status === 200) {
                    setVillage(response.data.data)
                } else {
                    setVillage([])
                }
            })
        }

        setVillageId(0)
        setTps([])
        setTpsId(0)

        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)

        getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });

    }

    const handleVillage = async (e) => {
        const villageId = e.target.value;
        setVillageId(villageId)
        console.log(villageId);

        if (villageId === 0) {
            setTps([])
        } else {
            getTps(villageId).then((response) => {
                setTpsId(0)
                if (response && response.status === 200) {
                    setTps(response.data)
                } else {
                    setTps([])
                }
            });
        }

        setTpsId(0)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)

        getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleTps = async (e) => {
        const tpsId = e.target.value;
        setTpsId(tpsId)
        setIsLoading(true)
        setRefresh(true)
        setConnection(true)

        setCurrentPage(1)
        getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handleSearchChange = (e) => {
        const newKeyword = e.target.value;
        setKeyword(newKeyword)
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsLoading(true)
        searchCalegRelawan(accessToken, roleName, caleg_id, keyword).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newCurrentPage = currentPage - 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setRelawan(response.data.data);
                    setTotalRelawan(response.data.meta.total);
                    setNumber(response.data.meta.from)
                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setRelawan([])
                }
            });
        }
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            const newCurrentPage = currentPage + 1
            setCurrentPage(newCurrentPage)
            setIsLoading(true)
            getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
                setIsLoading(true)
                setConnection(true)
                if (response && response.status === 200) {
                    setRefresh(false)
                    setIsLoading(false)
                    setRelawan(response.data.data);
                    setTotalRelawan(response.data.meta.total);
                    setNumber(response.data.meta.from)
                    setCurrentPage(response.data.meta.current_page)
                    setLastPage(response.data.meta.last_page)
                } else {
                    setIsLoading(false)
                    setRelawan([])
                }
            });
        }
    }

    useEffect(() => {
        const roleName = `${localStorage.getItem('role_name')}`
        const accessToken = `Bearer ${localStorage.getItem('access_token')}`
        setRoleName(roleName)
        setAccesToken(accessToken)
        getCalegRelawan(accessToken, roleName, caleg_id).then((response) => {
            setIsLoading(true)
            setConnection(true)
            if (response && response.status === 200) {
                setRefresh(false)
                setIsLoading(false)
                setRelawan(response.data.data);
                setTotalRelawan(response.data.meta.total);
                setNumber(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            } else {
                setIsLoading(false)
                setRelawan([])
            }
        });

        getDistrict(regencyId).then((response) => {
            if (response && response.status === 200) {
                setDistrict(response.data.data)
            } else {
                setDistrict([])
            }

        })
    }, [caleg_id, navigate]);
    return (
        <>
            <Helmet>
                <title>Relawan Caleg - SPARTA 24</title>
            </Helmet>
            <LayoutSuperAdmin>
                {/* Desktop Header */}
                <div class="sticky top-0 w-full h-14 bg-yellow-400 z-30 hidden md:block">
                    <div class="w-full h-14 bg-blue-500 rounded-tl-full flex items-center justify-between px-5 text-white">
                        <a href={`/dashboard/caleg/${type_id}/detail/${caleg_id}`}>
                            <FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon>
                            <span className="ml-3 font-semibold">Kembali</span>
                        </a>
                        <div className="flex items-center space-x-3">
                            <form onSubmit={handleSearch}>
                                <div className="flex items-center text-sm pl-3">
                                    <input
                                        type="search"
                                        value={keyword}
                                        onChange={handleSearchChange}
                                        className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                        placeholder="telusuri"
                                    ></input>
                                    <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>

                            <div className="flex items-center space-x-3 font-semibold mb-5 md:mb-0">
                                {refresh === true ?
                                    <div className="flex items-center justify-center w-10 h-10">
                                        <div
                                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div> :
                                    <p className="font-semibold text-2xl">{totalRelawan.toLocaleString('id-ID')}</p>
                                }
                                <p>Relawan</p>
                            </div>
                        </div>

                        {/* <div className="flex items-center space-x-3">
                            <button id="" type="button" class="font-semibold text-sm space-x-3 bg-green-400 px-5 py-2 rounded-md text-black hover:bg-green-500 transition-colors">
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                                <span>Export Data</span>
                            </button>
                        </div> */}
                    </div>
                </div>

                {/* Mobile Header */}
                <div className="w-full sticky top-0 bg-blue-500 py-3 px-3 space-y-3 md:hidden z-40">
                    <div className="flex items-center space-x-3">
                        <form onSubmit={handleSearch}>
                            <div className="flex items-center text-sm ">
                                <input
                                    type="search"
                                    value={keyword}
                                    onChange={handleSearchChange}
                                    className="px-3 py-2 bg-white rounded-l-md outline-none text-black"
                                    placeholder="telusuri"
                                ></input>
                                <button type="submit" className="w-fit py-2 px-4 text-black font-semibold rounded-r-md bg-yellow-400 hover:bg-yellow-500">
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </button>
                            </div>
                        </form>
                        <button type="button" className="w-fit py-2 px-4 text-black font-semibold rounded-md bg-yellow-400 hover:bg-yellow-500">
                            <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                        </button>
                    </div>
                    <div className="flex items-center space-x-3 font-semibold md:mb-0 text-white">
                        {refresh === true ?
                            <div className="flex items-center justify-center w-10 h-10">
                                <div
                                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status">
                                    <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                        Loading...
                                    </span>
                                </div>
                            </div> :
                            <p className="font-semibold text-2xl">{totalRelawan.toLocaleString('id-ID')}</p>
                        }
                        <p>Relawan</p>
                    </div>
                </div>
                {/* End Mobile Header */}

                {/* Filter Desktop Version */}
                <div className="w-full hidden md:flex justify-between items-center px-5 py-3">
                    <div className="flex items-center space-x-3 text-sx">
                        <select data-te-select-init data-te-select-filter="true" onChange={handleRegency}>
                            <option value="3205">KABUPATEN GARUT</option>
                            <option value="3206">KABUPATEN TASIKMALAYA</option>
                            <option value="3278">KOTA TASIKMALAYA</option>
                        </select>
                        <select value={districtId} data-te-select-init data-te-select-filter="true" onChange={handleDistrict}>
                            <option value="" className="text-sm">Kecamatan</option>
                            {district?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={villageId} data-te-select-init data-te-select-filter="true" onChange={handleVillage}>
                            <option value="" className="text-sm">Kelurahan</option>
                            {village?.map((item) => (
                                <option value={item.id} >{item.name}</option>
                            ))}
                        </select>

                        <select value={tpsId} data-te-select-init data-te-select-filter="true" onChange={handleTps}>
                            <option value="0" className="text-sm">No TPS</option>
                            {tps?.map((item) => (
                                <option value={item.tps_number} >{item.tps_number}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* End Filter Desktop Version */}

                <div className="w-full px-3 md:px-5 overflow-x-auto scrollbar-thin scrollbar-track-black scrollbar-thumb-blue-700 scrollbar-track-rounded-full scrollbar-thumb-rounded-full relative ">
                    {/* Desktop Tabel */}
                    <table className="min-w-max w-full hidden md:block">
                        <thead className="">
                            <tr className="text-sm w-full">
                                <th className="w-16 py-2 aspect-square border-2 ">No</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">No Kartu Keluarga</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Nik</th>
                                <th className="w-72 py-2 aspect-square border-2 px-2 text-start overflow-auto">Nama</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kota / Kabupaten</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Kecamatan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-start ">Desa / Kelurahan</th>
                                <th className="w-fit py-2 aspect-square border-2 px-2 text-center ">TPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading === false ?
                                relawan.map((dataRelawan) => (
                                    <tr className={`text-sm ${number % 2 === 1 ? 'bg-white hover:bg-gray-400' : 'bg-gray-200 hover:bg-gray-400'}`}>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer text-center">{number++}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer bg-gray px-3 w-fit">{dataRelawan.nkk}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.nik}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.nama}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kabupaten}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kecamatan}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-2 py-2 w-fit">{dataRelawan.alamat?.kelurahan}</td>
                                        <td onClick={() => navigate(`/dashboard/caleg/${caleg_id}/relawan/detail/${dataRelawan.id}`)} className="cursor-pointer px-3 py-2 text-center">TPS {dataRelawan?.no_tps}</td>

                                    </tr>
                                )) : Array.from({ length: 20 }, (_, j) => (
                                    <tr key={j} className="animate-pulse w-full">
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <td key={i} className="px-3 py-2">
                                                <div className="h-5 bg-slate-200 rounded"></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {/* End Desktop Tabel */}

                    {/* Mobile Version Card */}
                    <div className="space-y-3 pt-3 md:hidden">
                        {isLoading === false ?
                            relawan.map((dataRelawan) => (
                                <div onClick={() => navigate(`/dashboard/relawan/detail/${dataRelawan.id}`)} className="w-full h-16 bg-yellow-50 drop-shadow-md flex items-center justify-between text-sm rounded-md px-3">
                                    <div className="flex space-x-3">
                                        {dataRelawan.image == null ?
                                            <div className="h-10 aspect-square bg-yellow-400 rounded-full"></div> :
                                            <img src={dataRelawan.image} alt="Relawan Sparta" className="w-10 aspect-square rounded-md object-cover object-center"></img>}
                                        <div>
                                            <p className="font-semibold ">{dataRelawan.nama}</p>
                                            <p>{dataRelawan.nik}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center">
                                        <p>TPS</p>
                                        <p>{dataRelawan.no_tps}</p>
                                    </div>
                                </div>
                            )) : ''}
                    </div>
                    {/* End Card Mobile Version */}

                    <div className="w-full bg-white h-3"></div>
                    <div className="w-full flex items-center justify-end space-x-3 py-5 bg-gray-200 px-5">
                        <button type="button"
                            onClick={handlePreviousPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                            <span className="ml-3">Kembali</span>
                        </button>
                        <p className="text-xl font-bold ">{currentPage}</p>
                        <button type="button"
                            onClick={handleNextPage}
                            className="text-yellow-500 hover:text-yellow-700 transition-colors">
                            <span className="mr-3">Selanjutnya</span>
                            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
                        </button>
                    </div>
                </div>
            </LayoutSuperAdmin>
        </>
    )
}

export default CalegRelawan